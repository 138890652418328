.media-picker {
  display: flex;
  flex-direction: column;

  .media-picker-grid {
    gap: 20px;
  }

  .media-picker-loader {
    display: flex;
    flex-direction: column;
    height: 100%;

    .well-loading {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }

  .media-list {
    flex: 1;
  }
}

.media-picker-filters {
  display: flex;
  margin: 1rem (-$element-padding / 2);
  align-items: center;

  .control-group {
    margin: 0;
  }

  @include mobile-only {
    display: block;
  }

  .media-picker-tag-filter {
    flex: 1;
    padding: 0 $element-padding / 2;
  }

  .media-picker-type-filter {
    padding: 0 $element-padding / 2;
  }
}
