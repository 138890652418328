
.typeahead-result {
  cursor: pointer;

  &:hover {
    background-color: $light-gray;
  }
}

.typeahead-result-empty {
  background-color: $light-gray;
  padding: $element-padding;
}

.typeahead-result-pending {
  .well {
    border: 0;
  }
}
