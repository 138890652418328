$site-sidebar-width: 302px !default;
$site-sidebar-width-smallest-mobile: 260px !default;
$site-sidebar-collapsed-width: calc(#{$nav-sprite-size} + 46px) !default;
$site-sidebar-background-color: #242527 !default;
$site-sidebar-active-background-color: #4f545f !default;
$site-sidebar-trial-warning-color: #ffa000;
$site-sidebar-profile-avatar-size: 44px !default;
$site-sidebar-footer-font-size: 0.8rem !default;
$site-sidebar-footer-font-color: $dark-gray !default;
$site-sidebar-profile-width: 160px !default;
$sidebar-profile-summary-font-size: 0.875rem;
$sidebar-toggler-size: 32px !default;
$sidebar-toggler-background-color: $white !default;

.site-sidebar {
  @include flexbox;
  @include flex-dir(column);
  @include flex-just(space-between);
  position: relative;
  width: $site-sidebar-width;
  height: 100vh;
  background-color: $site-sidebar-background-color;
  overflow: visible;
  z-index: 2;

  @media (max-height: 680px) {
    overflow-y: auto;
  }

  & > .site-sidebar-body {
    @include flexbox;
    @include flex-dir(column);
    @include flex-just(space-between);
    width: 100%;
    height: 100%;
    overflow: visible;

    & > .overflow {
      overflow: hidden;
      width: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      & > .sidebar-profile-container {
        @include flexbox;
        @include align-items(center);
        @include flex-wrap(nowrap);
        @include flex-just(space-between);
        width: $site-sidebar-width;
        padding-top: $element-padding;
        padding-right: 33px;
        padding-bottom: 33px;
        box-sizing: border-box;
        overflow: visible;

        @include smallest-mobile-only {
          width: $site-sidebar-width-smallest-mobile;
        }

        & > .sidebar-profile {
          @include flexbox;
          @include align-items(center);
          color: $white;
          // white-space: nowrap;

          & > .sidebar-profile-avatar-container {
            @include flex(0, 0, auto);
            width: $site-sidebar-collapsed-width;
            // margin: 0px $element-padding / 3;
            margin: 0 12px 0 20px;
            box-sizing: border-box;
            cursor: pointer;
            max-width: $site-sidebar-profile-avatar-size;

            & > .border {
              border: 1px solid #fff;
            }

            .sidebar-profile-avatar {
              display: block;
              width: $site-sidebar-profile-avatar-size;
              height: $site-sidebar-profile-avatar-size;
              // margin: 0 auto;
              border-radius: 50%;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #8c8c8c;
              font-weight: 700;
              line-height: 21px;
              font-size: 16px;
            }
          }

          & > .sidebar-profile-summary {
            max-width: $site-sidebar-profile-width;
            font-size: $sidebar-profile-summary-font-size;

            & > .sidebar-profile-role {
              color: $muted-text-color;
              font-size: 14px;
              line-height: 14px;
              font-weight: normal;
              color: #ffffff;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              .plus-icon {
                &.business {
                  background-color: $blue;
                }
              }
            }

            & > .sidebar-profile-name {
              font-size: 16px;
              font-weight: bold;
              line-height: 16px;
              margin: 2px 0 7px 0;
            }

            & > .sidebar-profile-trial-user {
              & > span {
                & > a {
                  &.sidebar-upgrade {
                    @include anchor-reset;
                  }
                }
              }

              &.trial-warning {
                & > span {
                  & > a {
                    color: $site-sidebar-trial-warning-color;
                  }
                }
              }
            }
          }
        }

        & > .sidebar-profile-dropdown {
          position: relative;
          overflow: visible;
          z-index: 1;

          & > a {
            &.dropdown-toggle {
              color: $white;

              &:hover {
                opacity: $hover-opacity;
              }
            }
          }

          & > .dropdown-menu {
            position: absolute;
            // left: -137px;
            right: 0;
            padding: 5px 0;
            background-color: #fff;
            border-radius: 8px;
            border-color: #fff;
            z-index: 1000;
            box-shadow: -3px 5px 12px 1px rgba(0, 0, 0, 0.15);

            &:before {
              content: "\00a0";
              position: absolute;
              top: 23px;
              left: -15px;
              width: 1px;
              height: 1px;
              border: 7px solid transparent;
              border-right-color: #fff;
            }
          }
        }
      }

      & > .sidebar-nav {
        width: 100%;
        color: $white;
        overflow: hidden;
        padding: 0px 10px;
        box-sizing: border-box;

        & > a {
          &.sidebar-nav-item {
            @include anchor-reset;
            @include flexbox;
            @include align-items(center);
            padding-bottom: 0.9rem;
            color: $white;
            transition: opacity 0.2s ease-in, background-color 0.2s ease-in;
            opacity: 0.6;

            & > .sidebar-nav-item-wrapper {
              @include anchor-reset;
              @include flexbox;
              @include align-items(center);
              display: flex;
              width: 100%;
              margin-left: 1px;
              padding: 13px 14px;
              box-sizing: border-box;
              font-size: 16px;
              line-height: 16px;
              font-weight: 400;
              color: #fff;
              overflow: hidden;

              & > .ico {
                margin-right: 12px;
                font-size: 24px;
                color: #fff;
                font-weight: 500;
              }
            }

            &.active .sidebar-nav-item-wrapper {
              background: #00000020;
              border-radius: 16px;

              & > i {
                margin-right: 12px;
                color: #e54500;
              }
            }
          }
        }
      }
    }

    & > .site-sidebar-footer {
      width: $site-sidebar-width;
      padding: $element-padding;
      transition: opacity 0.2s ease-out;
      border-top: 1px $site-sidebar-footer-font-color solid;
      color: $site-sidebar-footer-font-color;
      font-size: $site-sidebar-footer-font-size;
      white-space: nowrap;
      box-sizing: border-box;
      opacity: 0;

      a {
        @include anchor-reset;
        color: $site-sidebar-footer-font-color;
      }
    }
  }

  .active {
    opacity: 1 !important;
  }

  .site-sidebar-nav {
    width: $site-sidebar-width;

    @include smallest-mobile-only {
      width: $site-sidebar-width-smallest-mobile;
    }
  }

  .arrow {
    position: absolute;
    right: -22px;
    top: 108px;
    width: $sidebar-toggler-size;
    height: $sidebar-toggler-size;
    background-color: $sidebar-toggler-background-color;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }
}
