$post-header-margin: 1.25rem !default;
$post-header-post-date-color: $muted-text-color !default;
$post-reply-margin-pull-up: -1rem !default;
$post-reply-pipe-offset: 20px !default;
$light-gray-color-replies: #f8f8f8 !default;
$post-reply-body-post-text-border-color: #dddddd !default;

.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $post-header-margin;

  .post-date {
    color: $post-header-post-date-color;
    text-align: right;
    white-space: nowrap;
  }
}

.post-user-actions {
  display: flex;
  align-items: center;
  color: #8c8c8c;
  line-height: 19.5px;

  .post-edit-controls {
    // margin-left: 20px;
  }
}

.post-header > .post-user-actions > .post-date {
  margin-right: 20px;
}

.post-text {
  hyphens: auto;
  //margin-bottom: $element-margin;
  overflow-wrap: break-word;
  white-space: pre-line;
  word-break: break-word;
  word-wrap: break-word;
}

.post-text-reply {
  hyphens: auto;
  //margin-bottom: $element-margin;
  overflow-wrap: break-word;
  white-space: pre-line;
  word-break: break-word;
  word-wrap: break-word;
}

// .post-resource-container {
//   margin: 20px 0;
// }

.post-count-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding: 0 20px;
}

.post-reply-body {
  .post-text {
    min-height: 1rem;

    .post-text-with-attachment {
      border-bottom: 0;
      border-radius: 0;
    }
  }

  & .post-text-reply:empty {
    display: none !important;
    opacity: 0 !important;
  }
}

.post-attachment-resize-reply {
  width: 200px;
}

.post-replies {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .feed-post {
    // margin-bottom: $element-margin;
  }
}
