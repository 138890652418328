$roster-list-item-description-spacing: $element-padding !default;
$roster-list-item-sport-color: $muted-text-color !default;

.roster-list {
  .roster-description {
    display: inline-block;
    flex: 1 1 auto;
    margin-left: $roster-list-item-description-spacing;
  }

  .roster-count {
    color: $roster-list-item-sport-color;
  }

  .roster-name {
    margin: 0;
  }
}
