// ====================
// Palette
// ====================
$facebook-color: #3a579c !default;
$orange: #e54500 !default;
$blue: #039be5 !default;
$light-blue: #f0faff !default;
$dark-blue: #0280bf !default;
$green: #43a047 !default;
$dark-green: #327836 !default;
$gray: #dddddd !default;
$light-gray: #f8f8f8 !default;
$dark-gray: #8c8c8c !default;
$darker-gray: #363636 !default;
$white: #ffffff !default;
$black: #000000 !default;
$transparent: rgba(255, 255, 255, 0);

$light-text-color: $white !default;
$dark-text-color: $black !default;

$danger-color: $orange !default;
$info-color: $blue !default;
$success-color: $green !default;
$active-color: $blue !default;
$inactive-color: #aaa !default;
$background-color: $light-gray !default;

$brand-primary-color: #f15500;
$brand-gradient-start-color: #e54500;
$brand-gradient-end-color: #ff6700;

$word-doc-blue: #2a5699 !default;
$excel-doc-green: #207245 !default;
$power-point-doc-orange: #dc5b26 !default;
$pdf-doc-red: #ef3535 !default;
$document-icon-color: #2a5699 !default;
$audio-icon-color: #dc5b26 !default;
$video-icon-color: #207245 !default;

$hover-opacity: 0.75 !default;

// ====================
// Animation Settings
// ====================
$transition-time: 0.3s !default;
$transition-time-fast: 0.15s !default;

// ====================
// Text Settings
// ====================

$font-family: "Inter", "Montserrat", "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$text-color: $dark-text-color !default;
$font-size: 1rem !default;
$font-size-small: 0.875rem !default;
$font-size-tiny: 0.75rem !default;

$line-height: 1.5 !default;

$h1-size: 2rem !default;
$h2-size: 1.5rem !default;
$h3-size: 1.25rem !default;
$h4-size: 1.125rem !default;

$link-color: $blue !default;
$muted-text-color: $dark-gray !default;

// ====================
// General Controls
// ====================
$element-margin: 1rem !default;
$element-padding: 1rem !default;
$control-height: 2.625rem !default;
$control-vertical-padding: ($control-height - (1rem * $line-height)) / 2 !default;
$control-horizontal-padding: 1rem !default;

$disabled-color: $light-gray !default;
$disabled-opacity: 0.65 !default;

$border-radius: 0.25rem !default;
$border-color: $gray !default;
$active-border-color: $active-color !default;

$input-background-color: $white !default;
$selected-option-color: rgba($blue, 0.06) !default;
$hover-option-color: $light-gray;

// ====================
// Tables
// ====================
$table-head-background-color: $background-color !default;
