@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

$site-sidebar-width: 302px !default;
$site-sidebar-width-smallest-mobile: 260px !default;
$site-sidebar-collapsed-width: calc(#{$nav-sprite-size} + 46px) !default;

.site-wrap {
  @include flexbox;
  @include flex-direction(column);
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > .site-wrap-body {
    @include flexbox;
    @include flex(1, 1, auto);
    width: 100%;
    height: 100%;
    min-height: 0;
    overflow: hidden;

    & > .site-sidebar {
      @include flex(1, 0, auto);

      @include smallest-mobile-only {
        width: $site-sidebar-width-smallest-mobile;
      }

      @include desktop-only {
        transition: width 0.2s ease-out;
        width: $site-sidebar-collapsed-width;

        // &:hover {
        //   width: $site-sidebar-width;
        // }

        & > .site-sidebar-footer {
          opacity: 0;

          // &:hover {
          //   opacity: 1;
          // }
        }
      }
    }

    .site-wrap-right-side {
      @include desktop-only {
        &:has(.non-scrollable) {
          & > .site-content {
            overflow: hidden !important;
            height: auto !important;
          }
        }
      }

      & > .site-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        padding: 0 20px;

        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }

      .site-wrap-header {
        position: absolute;
        z-index: 3;
        width: 100%;
      }

      @include flex(1, 0, auto);
      position: relative;
      width: 100%;
      transition: transform 0.2s ease-out;
      transform: translate3d(-$site-sidebar-width, 0, 0);

      @include smallest-mobile-only {
        transform: translate3d(-$site-sidebar-width-smallest-mobile, 0, 0);
      }

      background-color: #f8f8f8;
      box-sizing: border-box;
      z-index: 2;

      @include desktop-only {
        z-index: 1;
      }

      .site-content-inner {
        width: 100%;
        max-width: $mobile-breakpoint;
        height: 100%;
        margin: 0 auto;

        // @include mobile-only {
        //   max-width: 90%;
        // }
      }

      @include desktop-only {
        @include flex(1, 1, auto);
        transform: translate3d(0, 0, 0);
      }
    }

    &.propped,
    &.expanded {
      & > .site-sidebar {
        .site-sidebar-footer {
          opacity: 1;
        }
      }
    }

    &.expanded {
      & > .site-wrap-right-side {
        transform: translate3d(0, 0, 0);
      }

      @include mobile-only {
        & > .site-sidebar {
          & > .site-sidebar-body {
            & > .sidebar-profile-container {
              & > .sidebar-profile-dropdown {
                & > .dropdown-menu {
                  position: absolute;
                  top: 30px;
                  left: auto;
                  right: 0;

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.propped {
      & > .site-sidebar {
        @include desktop-only {
          width: $site-sidebar-width;
        }

        @include smallest-mobile-only {
          width: $site-sidebar-width-smallest-mobile;
        }

        & > .site-sidebar-body {
          & > .sidebar-profile-container {
            & > .sidebar-profile-dropdown {
              & > .dropdown-menu {
                position: absolute;
                top: 30px;
                left: auto;
                right: 0;

                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .dark {
    color: #3c4048;
  }

  .btn-create {
    // padding: 11.5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 44px;
    border: 1px #dddddd solid;
    border-radius: 8px;
    color: #039be5;
    margin: 0 20px 0 0;
  }
}

.site-content-inner:has(.schedule-container) {
  max-width: $mobile-breakpoint-large !important;
}

.site-content-inner:has(.account-settings-container) {
  max-width: $mobile-breakpoint-large !important;
}
