$coach-plus-feature-icon-size: 35px !default;
$coach-plus-feature-icon-spacing: 1rem !default;
$coach-plus-feature-description-text-color: $muted-text-color !default;
$coach-plus-feature-palette: (
  'spaces': #e54500,
  'library': #43a047,
  'storage': #ffa000,
  'segments': #039be5,
  'templates': #5e35b1
) !default;
$coach-plus-upgrade-primary-color: $danger-color !default;
$coach-plus-upgrade-primary-accent-color: rgba($coach-plus-upgrade-primary-color, 0.1) !default;
$coach-plus-upgrade-chip-size: 1.75rem !default;
$coach-plus-upgrade-chip-spacing: 0.5rem !default;

.coach-plus-upgrade-cta-btn-group {
  text-align: right;

  @include mobile-only {
    text-align: center;
  }
}

.coach-plus-upgrade-cta-btn {
  margin-left: 0.1rem;
  margin-right: 0.1rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.coach-plus-upgrade-cta-btn-trial {
    @include mobile-only {
      margin-bottom: 0.75rem;
    }
  }
}

.coach-plus-feature-list {
  .coach-plus-feature {
    vertical-align: top;
  }
}

.coach-plus-feature {
  .feature-icon {
    padding-right: $coach-plus-feature-icon-spacing;
    width: $coach-plus-feature-icon-size;

    @include mobile-only {
      display: none;
    }
  }

  .feature-description {
    color: $coach-plus-feature-description-text-color;
  }

  @each $name, $color in $coach-plus-feature-palette {
    &.coach-plus-feature-#{$name} {
      .feature-title {
        color: $color;
      }
    }
  }
}

.coach-plus-upgrade-chip {
  color: $coach-plus-upgrade-primary-color;
  font-size: $coach-plus-upgrade-chip-size;
  margin-left: $coach-plus-upgrade-chip-spacing;
}

.coach-plus-upgrade-btn {
  background-color: $coach-plus-upgrade-primary-accent-color;
  border-color: $coach-plus-upgrade-primary-color;
  color: $coach-plus-upgrade-primary-color;
  font-weight: bold;
  padding-left: 1.75rem;
  position: relative;

  &:hover {
    background-color: $coach-plus-upgrade-primary-color;
    color: $white;
  }

  .btn-icon {
    display: inline;
    left: 0;
    line-height: 1.25rem;
    padding-left: 0.5rem;
    position: absolute;
  }
}
