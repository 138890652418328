$datepicker-selected-fill-color: $blue !default;

.datepicker {
  // scss-lint:disable SelectorDepth, NestingDepth, QualifyingElement
  //  Selectors must match base style.
  table {
    tr {
      td {
        &.active,
        &.active.active,
        & span.active,
        & span.active.active {
          background-color: $datepicker-selected-fill-color;
          background-image: none;
          text-shadow: none;

          &:hover {
            background-color: hover-color($datepicker-selected-fill-color);
          }
        }
      }
    }
  }
}
