.table-show {
  width: 100%;

  td {
    padding:0.35em;
    vertical-align:top;
  }

  td,
  td p {
    font-size:16px !important;
  }

  td.th {
    color: #9E9E9E;
    width: 25%;
    padding-right:3%;
    text-align:right;
  }
}

@media (max-width: 767px) {
  .table-show td.th {
    width: 40%;
  }
}

.t-panel {
  border: 1px solid #e3e3e3;
  min-height:182px;
  background:#fff;
  .table.table-panel {
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -khtml-border-radius: inherit;
    border-collapse: separate;
  }
}

.table {
  &.table-panel {
    .control-group {
      margin: 0;
      text-align: left;
    }

    input,
    select,
    form {
      margin-bottom: 0;
    }

    th {
      border-top: 0;
    }

    th,
    td {
      padding-left: 13px;
    }

    td {
      line-height: 42px;
    }
  }

  td {
    &.full {
      width: 100%;
    }

    &.middle {
      vertical-align: middle;
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }
}
