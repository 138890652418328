$burger-transition-time: 0.2s !default;
$burger-hover-opacity: 0.6 !default;
$burger-width: 24px !default;
$burger-height: 1rem !default;
$burger-bar-width: 3px !default;
$burger-bar-color: $white !default;
$burder-bar-color-black: #3C4048 !default;

button.burger { // scss-lint:disable QualifyingElement
  @include button-reset;
  overflow: visible;
}

.burger {
  height: $burger-height;
  transition: opacity $burger-transition-time ease-in;
  width: $burger-width;

  &:hover {
    opacity: $burger-hover-opacity;
  }
}

.burger-holder {
  height: 100%;
  position: relative;
  width: 100%;
}

.bars-black {
  &,
  &:before,
  &:after {
    background-color: $burder-bar-color-black!important;
  }
}

.burger-bars {
  margin-top: -$burger-bar-width / 2;
  top: 50%;

  &,
  &::before,
  &::after {
    background-color: $burger-bar-color;
    content: "";
    height: $burger-bar-width;
    left: 0;
    position: absolute;
    transition: transform $burger-transition-time ease-out;
    width: 100%;
  }

  &::before {
    transform: translateY(-$burger-height / 2);
  }

  &::after {
    transform: translateY($burger-height / 2);
  }
}

.expanded {
  .burger-bars {
    transform: rotateZ(-45deg);

    &::before {
      transform: translateY(0) rotateZ(90deg);
    }

    &::after {
      transform: translateY(0) rotateZ(90deg);
    }
  }
}
