
$team-group-list-scroller-height: 330px;

.team-group-horizontal-tabs {
  display: flex;
  overflow: auto;
  white-space: nowrap;

  .team-group-tab {
    padding: 0.5 * $element-padding 0.75 * $element-padding;
  }

  @include desktop-only-large {
    display: none;
  }
}

ul {
  &.team-group-tabs {
    @include list-reset;
  }

  &.team-group-horizontal-tabs {
    margin: 0 $element-margin;
    padding: 0 0 1rem;

    li {
      display: inline-block;
    }
  }
}

.team-group-tab {
  @include border;
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 12px;
  color: $text-color;
  display: flex;
  height: 100%;
  max-width: $team-group-nav-width;
  padding: 0.75rem 1.5rem;
  transition: color 0.2s ease-out;
  width: 100%;

  .team-group-icon {
    font-size: 1.75rem;

    + .team-group-summary {
      margin-left: 1.5rem;
    }
  }

  & > .team-group-summary > div:first-child {
    font-weight: 600;
  }

  &.active {
    background-color: $white;
    color: $active-color;
  }

  &.highlight {
    color: $active-color;
  }

  &:hover {
    color: $active-color;
  }
}

button {
  &.team-group-tab {
    @extend .team-group-tab;
    @include button-reset;
  }
}

a {
  &.team-group-tab {
    @extend .team-group-tab;
    @include anchor-reset;
  }
}

.team-group-list {
  .team-group-add-join-btn {
    color: $active-color;
    font-size: $font-size-small;
    transition: color 0.2s ease-out;
    background-color: #039BE51A;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 100%;
    width: 32px;
    height: 32px;

    &:hover {
      color: hover-color($active-color);
    }
  }

  button {
    &.team-group-add-join-btn {
      @extend .team-group-add-join-btn;
      @include button-reset;
    }
  }

  .team-group-list-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: $element-margin;
    align-items: center;

    & > label {
      color: #3C4048;
    }
  }

  .team-group-list-body {
    // border-top: 1px $border-color solid;

    .team-group-tabs {
      margin-top: -1px;
    }

    &.scroll {
      border-bottom: 1px $border-color solid;
      max-height: $team-group-list-scroller-height;
      overflow: auto;

      .team-group-tab-list {
        margin-bottom: -1px;
      }
    }
  }
}

.team-group-side-nav {
  width: $team-group-nav-width;

  @include mobile-only-large {
    display: none;
  }
}

