$feed-size: 868px;

.feed-container {
  width: 100%;

  @include desktop-only {
    max-width: $feed-size;
    margin: 0 auto;
  }


}
