.column {
  display: flex;
  flex-direction: column;
}

.cursor_pointer {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
}

.flex_wrap {
  flex-wrap: wrap;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
}

.center_vertical {
  display: flex;
  align-items: center;
}

.center_horizontal {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.gap_40_20 {
  gap: 40px 20px;
}

.m_0 {
  margin: 0;
}

.mt_0 {
  margin-top: 0 !important;
}

.mt_10 {
  margin-top: 10px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_5 {
  margin-top: 6px;
}

.mt_8 {
  margin-top: 8px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_20 {
  margin-top: 20px;
}

.mb_5 {
  margin-bottom: 10px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mt_50 {
  margin-top: 50px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_120 {
  margin-left: 120px;
}

.ml_200 {
  margin-left: 200px;
}

.ml_50 {
  margin-left: 50px;
}

.mr_5 {
  margin-right: 5px;
}

.mr_15 {
  margin-right: 15px;
}

.w_100 {
  width: 100%;
}

.w_50 {
  width: 50%;
}

.p_10 {
  padding: 10px;
}

.p_13_8 {
  padding: 13px 8px;
}

.pt_20_20 {
  padding: 20px 0 20px 0;
}

.pt_5 {
  padding-top: 5px;
}

.pt_8 {
  padding-top: 8px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_16 {
  padding-top: 16px !important;
}

.pl_120 {
  padding-left: 120px;
}

.p_5_10 {
  padding: 5px 10px;
}

.line_height_12 {
  line-height: 12px !important;
}

.heading {
  font-size: 36px;
  line-height: 20px;
  margin-bottom: 13px;
}

.description {
  font-size: 18px;
  line-height: 20px;
}

.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Rounded slider */
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--confirmed-green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--confirmed-green);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Custom radio button */
.item {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  height: 30px;
  cursor: pointer;
  user-select: none;
  color: var(--text-color);
}
.label {
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  top: 20%;
}

.item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: white;
  border: 1px solid var(--fc-border-color);
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.item input:checked ~ .checkmark {
  border: 1px solid var(--selection);
  background-color: var(--selection);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.item .checkmark:after {
  inset: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.grey_background {
  background-color: var(--date-picker-grey);
}

.font_bold {
  font-weight: bold;
}

.text_color {
  color: var(--text-color);
}

.content_container {
  width: 100%;
  /* Navbar has a 76px height */
  height: calc(100vh - 76px);
}

.content_wrapper {
  /* Left navbar has a 68px width */
  width: calc(100% - 68px);
  min-height: calc(100vh - 76px);
  overflow-y: auto;
}

.content_wrapper_100 {
  /* Left navbar has a 68px width */
  width: 100%;
  min-height: calc(100vh - 76px);
  overflow-y: auto;
}

.relative {
  position: relative;
}

.fw_800 {
  font-weight: 800;
}

.fw_700 {
  font-weight: 700;
}

.fw_600 {
  font-weight: 600;
}

.fw_400 {
  font-weight: 400;
}

.fs_20 {
  font-size: 20px;
}

.fs_18 {
  font-size: 18px;
}

.fs_16 {
  font-size: 16px;
}

.lh_22 {
  line-height: 22px;
}

.light_text_color {
  color: var(--avatar-background);
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.scroll_y {
  overflow-y: scroll;
}

.input_container {
  resize: none;
  border: none;
  background: var(--button-grey);
  border-radius: 8px;
  height: 40px;
  padding: 0px 8px;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.input_container input::placeholder {
  color: var(--input-placeholder-color);
  opacity: 1;
}

.input_container input {
  border: none;
  outline: none;
  background: var(--button-grey);
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_container_disabled input {
  color: var(--input-placeholder-color);
}

.input_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_input_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.error_message {
  color: var(--alert-red);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.circled_label {
  border: 1px solid var(--fc-border-color);
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
  border-radius: 16px;
  /* margin: 20px 20px 20px 20px; */

  /* padding-left: 20px; */
}

.circled_label p {
  padding: 20px 20px 20px 20px;
}

.mlr_16 {
  margin-left: 16px;
  margin-right: 16px;
}
