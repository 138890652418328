$upload-dropzone-border-width: 2px;

.upload-dropzone {
  height: 100%;
  padding: $upload-dropzone-border-width;
  position: relative;
  z-index: 0;

  .upload-dropzone-inner {
    align-items: center;
    background: $white;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .upload-dropzone-content {
    text-align: center;
  }

  .upload-dropzone-border {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .upload-dropzone-border-inert {
    background: repeating-linear-gradient(
      45deg,
      $white,
      $white 0.5rem,
      $border-color 0.5rem,
      $border-color 1rem
    );
  }

  .upload-dropzone-border-active {
    background: repeating-linear-gradient(
      45deg,
      $white,
      $white 0.5rem,
      $active-color 0.5rem,
      $active-color 1rem
    );
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  &.highlight {
    .upload-dropzone-border-active {
      opacity: 1;
    }
  }
}

