$select-list-select-item-color: $light-gray;
$select-list-dropdown-max-height: 10rem;

.select-list {
  .select-list-dropdown {
    max-height: $select-list-dropdown-max-height;
    overflow-y: auto;
    width: 100%;
  }

  &:not(.disabled) {
    cursor: text;

    &.focused {
      border-color: $active-border-color;
    }
  }

  &.disabled {
    @include disable;
    background-color: $disabled-color;
  }
}
