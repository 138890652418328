.scheduled-layout {
  width: 100%;

  .scheduled-calendar-container {
    width: 280px;
    float: right;
    padding: 0 0 0 $element-margin / 2;
  }

  .scheduled-feed-container {
    width: 100%;
    float: left;
    padding: 0;
    box-sizing: border-box;

    @include desktop-only-large {
      width: calc(100% - 288px);
      padding: 0 $element-margin / 2 0 0;
    }
  }
}
