.space-header-panel {
  padding: 0;
}

.profile-block {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar + .profile-block-details {
    margin-left: $element-margin;
  }

  .profile-block-title,
  .profile-block-summary {
    display: flex;
    flex-wrap: wrap;
    color: #000 !important;
  }
}

.profile-block-title {
  font-weight: bold;
}

.break-words {
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
  max-width: 200px;
  overflow: hidden;
}

.profile-block-summary {
  color: $muted-text-color;
  ul.dropdown-menu {
    max-height: 230px;
    overflow-y: scroll;
  }
}

.profile-block-header {
  .profile-block-title {
    margin: 0;
    line-height: 1.125;
  }

  .profile-block-summary {
    color: $muted-text-color;
  }
}

.locations-block-summary {
  color: #039be5;
  font-size: 14px;
}
