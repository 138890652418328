$site-navbar-logo-height: 32px !default;
$site-navbar-query-max-width: 360px !default;

.site-navbar {
  @include flexbox;
  @include align-items(center);
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  // width: 100%;
  height: calcRems(80px);
  padding: 18px 0;
  color: $white;
  box-sizing: border-box;

  @include mobile-only {
    padding: $element-padding 0;
    justify-content: space-between;
  }

  .site-sidebar-toggle {
    display: inline-block;
    margin: 0 1.5rem;
  }
}

.site-navbar-left {
  @include flex-shrink(0);

  @include desktop-only {
    min-width: calc(
      #{$site-sidebar-width} - #{1.5 * $element-padding}
    ); // sidebar width - toolbar padding - query padding

    display: flex;
    justify-content: flex-start;
    margin: 24px 0px 40px 20px;
  }
}

.site-navbar-logo {
  width: auto;
  height: $site-navbar-logo-height;
}

.site-navbar-query {
  max-width: $site-navbar-query-max-width;
  @include flex(1, 0, auto);
  margin: 0;

  .input-append {
    @include flexbox;
    height: calcRems(43px);
    margin: 0 20px 0 0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & ,input {
      background-color: hsl(0, 0%, 96%);
    }

    input {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 0;
    }

    i {
      font-size: 24px;
      margin: 0 14px;
    }
  }
}

.site-navbar-toolbar {
  @include flexbox;
  @include align-items(center);
  @include flex-just(flex-end);
  width: 100%;
  // padding: 0 $element-padding;
  padding: 0 33px 0 0;

  // .site-navbar-query,
  // .site-navbar-action {
  //   padding: 0 $element-padding / 2;
  // }

  .site-navbar-actions {
    @include flex(0, 1, auto);
  }

  @include desktop-only {
    align-self: center;
    // @include flex-just(space-between);
  }

  @include mobile-only {
    width: auto;
    padding: 0 20px;
  }
}

ul {
  &.site-navbar-actions {
    @include list-reset;

    > li {
      display: inline-block;
    }
  }
}

.site-navbar-action {
  // padding: 0 $element-padding / 2;
  transition: opacity 0.2s ease-in;
  background-color: transparent;
  color: $white;
  font-size: 1.5rem;
  line-height: 1;
  vertical-align: middle;

  &:hover {
    opacity: $hover-opacity;
  }
}

a {
  &.site-navbar-action {
    // scss-lint:disable QualifyingElement
    @include anchor-reset;
    @extend .site-navbar-action;
  }
}

button {
  &.site-navbar-action {
    // scss-lint:disable QualifyingElement
    @include button-reset;
    @extend .site-navbar-action;
  }
}
