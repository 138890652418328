.bullets {
  display: inline-block;

  &::after {
    content: "\2B24\2B24\2B24";
    font-size: 4px;
    letter-spacing: 0.5em;
    vertical-align: middle;
    color: #8c8c8c;
  }
}
