%upgrade-indicator-fold {
  overflow: hidden;
  position: relative;

  &::before {
    @include border;
    background-color: $brand-primary-color;
    content: "";
    height: 1.5rem;
    left: 50%;
    position: absolute;
    top: 0;
    transform: rotateZ(45deg);
    width: 100%;
  }

  &::after {
    @extend %coach-now-plus-sprite;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 10px;
    margin: 0.25rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
  }
}

@mixin theme-upgrade-indicator-fold-size($size) {
  &::before {
    height: $size;
  }
}
