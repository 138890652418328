$activity-item-new-activity-color: $selected-option-color;
$activity-list-max-height: 20rem;

.activity-scroll-container {
  max-height: $activity-list-max-height;
  overflow: auto;
}

.activity-list-group-set {
  margin: 16px 0;

  > .activity-list-group:last-child {
    margin-bottom: 0;
  }
}

.activity-list-group {
  margin-bottom: $element-margin;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  %activity-item {
    // @include border;
    border: 1px solid #f4f4f4;
    border-radius: 12px;
    background-color: $white;
    box-sizing: border-box;
    display: block;
    padding: $element-padding;
    text-align: left;
    transition-duration: 0.2s;
    // transition-property: background border;
    transition-timing-function: ease-out;
    width: 100%;

    &:not(:first-child) {
      margin-top: -1px;
    }

    &.new {
      background-color: $activity-item-new-activity-color;
    }
  }

  .activity-item {
    @extend %activity-item;
  }

  button.activity-item {
    // scss-lint:disable QualifyingElement
    @include button-reset;
    @extend %activity-item;
  }
}
