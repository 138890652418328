%button-reset {
  background: transparent;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 1rem;
  margin: 0;
  outline: 0; // scss-lint:disable ImportantRule
  overflow: hidden;
  padding: 0;

  &:hover {
    background-image: none;
  }

  &:disabled {
    cursor: default;
  }
}

@mixin button-reset {
  @extend %button-reset;
}
