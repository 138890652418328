$image-sizes: (
  'xs': 22px,
  'sm': 44px,
  'md': 58px
);

@each $name, $size in $image-sizes {
  .img-#{$name} {
    max-height: $size;
  }
}

.img-polaroid {
  display: inline-block;
}

.img-responsive {
  height: 100%;
  width: auto;
}
