$app-loader-background-color: $background-color;

.app-loader {
  align-items: center;
  background-color: $background-color;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
}
