@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

.rc-stripe-form {
  width: 100%;
  line-height: 1.5;

  & > .rc-loading-spinner {
    margin: 50px 0;
  }

  .card_details_wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    @include smallest-mobile-only {
      flex-direction: column;
    }
  }

  .buttons_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    // margin-top: 20px;

    .small_description {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      font-weight: 500;
      text-align: center;
    }
  }

  & .stripe-card-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .form-control {
      width: 100%;
      padding: 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      & > input {
        padding: 0.8rem 1.4rem;
        // border-color: #ccc !important; // sass-lint:disable-line no-important

        &:focus {
          border-color: #039be5;
        }
      }

      &.stripe {
        & > .form-input {
          & > :global(.StripeElement) {
            padding: 0.894rem 1.4rem;
            border: 1px #ccc solid;
            border-radius: 8px;
            outline: none;
            color: #363636;
            font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial,
              sans-serif;
            cursor: text;

            &:global(.StripeElement--focus) {
              border-color: #039be5;
            }

            &:global(.StripeElement--invalid) {
              border-color: #e54500;
            }
          }

          &.disabled {
            & > :global(.StripeElement) {
              background-color: #f8f8f8;
              border-color: #ccc;
              opacity: 0.65;
              cursor: default;
            }
          }
        }
      }

      &:global(.error) {
        & > input {
          border-color: #e54500 !important; // sass-lint:disable-line no-important
          color: #e54500;
        }

        &.stripe {
          & .form-input {
            & :global(.StripeElement) {
              border-color: #e54500;
            }
          }
        }
      }

      & button {
        &:last-of-type {
          float: right;
        }
      }
    }
  }
}

.select_states {
  padding-left: 20px;
  padding-right: 20px;
}
