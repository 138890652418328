
html {
  font-size: 93.75%;
  line-height: 93.75%;
}

body {
  background-color: $background-color;
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
}

a {
  color: $link-color;
  cursor: pointer;
}

a:link {
  color: $link-color;
}

a:hover {
  color: hover-color($link-color); // sass-lint:disable-line function-name-format
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: $element-margin / 2;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: $line-height;
}

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  font-size: $h4-size;
}

label,
input,
button,
select,
textarea {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: normal;
  line-height: $line-height;
}

// sass-lint:disable no-vendor-prefixes no-important
a,
input,
button {
  -ms-touch-action: none !important; // disable double-tap-to-zoom on some elements for IE
}

* {
  @supports (-webkit-overflow-scrolling: touch) {
    -webkit-overflow-scrolling: touch;
  }
}
// sass-lint:enable no-vendor-prefixes no-important
