$nav-sprites: (
  'feed': (0, 0),
  'spaces': (0, 1),
  'teams': (0, 2),
  'library': (0, 3),
  'tags': (0, 4),
  'segments': (0, 5),
  'scheduled': (0, 6),
  'templates': (0, 7),
  'support': (0, 8)
);

$nav-sprite-size: 28px;
$nav-sprite-atlas-width: 522px;
$nav-sprite-atlas-height: 58px;

@mixin nav-sprite($row, $column, $size) {
  $scale: $size / $nav-sprite-atlas-height;
  width: $nav-sprite-atlas-height * $scale;
  height: $nav-sprite-atlas-height * $scale;
  background-position: (-$nav-sprite-atlas-height * $column * $scale) (-$nav-sprite-atlas-height * $row * $scale);
  background-size: ($nav-sprite-atlas-width * $scale) ($nav-sprite-atlas-height * $scale);
}

.nav-sprite {
  display: inline-block;
  background-image: url('/images/nav-sprite.png');
  background-repeat: no-repeat;
}

@each $name, $pos in $nav-sprites {
  .nav-sprite-#{$name} {
    @include nav-sprite(nth($pos, 1), nth($pos, 2), $nav-sprite-size);
  }
}
