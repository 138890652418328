.post-count {
  color: $muted-text-color;
  font-size: 1rem;
  cursor: pointer;

  &.active {
    color: $blue;
  }

  .post-count-value {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }

  .post-count-icon {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;

    &.large-ico {
      font-size: 24px;
    }
  }
}

button {
  &.post-count {
    @include button-reset;
    white-space: nowrap;

    &:hover:not(:disabled) {
      background: transparent;
      color: $blue;
    }

    &:disabled {
      color: $muted-text-color;
    }
  }
}

.post-info {
  display: flex;
  flex-flow: column;

  .dummy-views-div {
    width: 200px;
  }

  @include desktop-only {
    flex-flow: row;
    justify-content: space-between;
    //padding: 20px 0;
  }

  @include mobile-only {
    flex-flow: row;
    justify-content: space-between;
    margin: 0 1rem;
  }
}

.post-tags {
  color: $muted-text-color;
  overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;

  @include desktop-only {
    // margin-left: 2 * $element-margin;
    max-width: 30rem;
  }
}
