$null-result-base-spacing: 0.5rem !default;
$null-result-hero-size: 250px !default;
$null-result-banner-size: 250px !default;
$null-result-banner-spacing: 1.5rem !default;
$null-result-heading-text-size: 1.75rem !default;
$null-result-summary-test-size: 0.875rem !default;

.null-result {
  text-align: center;

  &.null-result-banner {
    .null-result-hero {
      margin-bottom: $null-result-banner-spacing;
      max-width: $null-result-banner-size;

      @include desktop-only {
        max-width: $null-result-banner-size;
      }
    }
  }

  .null-result-hero {
    display: block;
    height: auto;
    margin: 0 auto $null-result-banner-spacing auto;
    max-width: $null-result-hero-size;
    width: 100%;

    @include desktop-only {
      max-width: $null-result-hero-size;
    }
  }

  .null-result-heading {
    display: block;
    font-size: $null-result-heading-text-size;
    line-height: 26.66px;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    color: #000;
  }

  .null-result-summary {
    display: block;
    font-size: $null-result-summary-test-size;
    margin-bottom: 0;
    margin: 0 auto;
    margin-top: $null-result-base-spacing;
    max-width: 25rem;
    color: #8c8c8c;
  }
}

.dialog {
  & > .null-result {
    width: 100%;
    height: 100%;

    .null-result-hero {
      height: 60%;
      width: initial;
      max-width: initial;
    }
  }
}
