$stack-list-max-result-height: 368px !default;

.stack-list {
  text-align: left;

  .stack-list-head + .well {
    border-radius: 0;
    margin-top: -1px;
  }

  &.scrollable {
    .stack-list-items {
      max-height: $stack-list-max-result-height;
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 20px;
    }
  }
}

.stack-list-head {
  @include border;
  background: $table-head-background-color;
  padding: $element-padding;
}

.stack-list-items {
  @include border;
  @include list-reset;

  .stack-list-item {
    box-sizing: border-box;
    display: block;
    padding: $element-padding;
    text-align: left;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px $border-color solid;
    }

    &.error {
      padding-bottom: 0;

      & > .error {
        display: inline-block;
        width: 100%;
        padding: .25rem .5rem;
        color: #ef3535;
        font-weight: 500;
        line-height: 1;
        box-sizing: border-box;
      }
    }
  }

  button {
    &.stack-list-item {
      @include button-reset;
      @extend .stack-list-item;
    }
  }

  a {
    &.stack-list-item {
      @include anchor-reset;
      @extend .stack-list-item;
    }
  }
}


.selectable-item {
  background-color: transparent;
  display: flex;
  opacity: 1;
  padding: $element-padding;
  position: relative;
  transition-duration: 0.2s;
  transition-property: opacity background-color;
  transition-timing-function: ease-out;

  &.active {
    background-color: $selected-option-color;

    .selectable-item-label {
      color: $active-color;
    }
  }

  &.disabled {
    background-color: $disabled-color;
    opacity: $disabled-opacity;

    .selectable-item-trigger {
      cursor: default;
    }
  }

  &.selectable-item-middle {
    align-items: center;
  }

  .selectable-item-trigger {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .selectable-item-control {
    margin-right: $element-margin;
  }

  .selectable-item-label {
    transition: color 0.2 ease-out;
  }

  .selectable-item-summary {
    color: $muted-text-color;
    font-size: $font-size-small;
  }
}

.stack-list-items { // sass-lint:disable-line no-mergeable-selectors
  .stack-list-item { // sass-lint:disable-line no-mergeable-selectors
    &.selectable-item {
      display: flex;
    }
  }
}
