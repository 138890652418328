@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

.coach_name {
  color: #000;
  font-weight: 700;
}

.parent_single_coach {
  .container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .timezone {
    font-size: 0.75rem;
    color: #8c8c8c;
    display: flex;
    gap: 0.25rem;
    align-items: center;

    i {
      font-size: 1rem;
    }
  }
}

.parent_multi_coach {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    @include mobile-only {
      height: 8rem;
    }
  }

  .info_text {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .coach_name {
    font-size: 14px;
  }

  .timezone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;

    .tz_ico {
      display: none;
    }
  }
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}


@media screen and (max-width: 400px) {
  .container  {
    flex-direction: row-reverse;
    height: 39px;
    justify-content: flex-end;
    padding: 1rem 0;
  }

  .info_text {
    align-items: flex-start;
  }
}
