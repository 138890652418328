$grid-cell-gutter: 1rem !default;
$grid-cell-ratios: 2, 3, 4, 5;

@mixin grid-gutter-variant($gutter) {
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;

  > .cell {
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  > .cell-padding {
    padding: 0 0.2rem;
  }
}

.grid {
  @include flexbox;
  @include flex-wrap(wrap);

  .cell {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    &.cell-auto {
      @include flex(1, 0, 0%);
      width: auto;
    }

    &.cell-collapse {
      width: auto;
    }

    @include desktop-only {
      &.cell-lg-auto {
        @include flex(1, 0, 0%);
        width: auto;
      }

      &.cell-lg-collapse {
        width: auto;
      }
    }

    @each $denominator in $grid-cell-ratios {
      @for $numerator from 1 to $denominator {
        &.cell-#{$numerator}of#{$denominator} {
          width: $numerator / $denominator * 100%;
        }

        &.cell-sm-#{$numerator}of#{$denominator} {
          @include mobile-only {
            width: $numerator / $denominator * 100%;
          }
        }

        &.cell-lg-#{$numerator}of#{$denominator} {
          @include desktop-only {
            width: $numerator / $denominator * 100%;
          }
        }
      }
    }
  }

  &.grid-gutters {
    @include grid-gutter-variant($grid-cell-gutter);
  }

  &.grid-sm-reverse {
    @include mobile-only {
      @include flex-flow(column-reverse);
    }
  }

  &.grid-middle {
    @include align-items(center);
  }
}
