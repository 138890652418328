.upload-progress {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .upload-progress-content {
    text-align: center;
    width: 100%;
  }

  .upload-progress-controls {
    align-items: center;
    display: flex;

    .progress-bar {
      flex: 1 0 auto;
    }

    .cancel-button {
      @include button-reset;
      color: $muted-text-color;
      margin-left: $element-margin / 2;
    }
  }
}
