.edit-team-member-modal {
  .avatar {
    display: block;
    margin: auto;
    margin-bottom: $element-margin;
  }

  @include mobile-only {
    .avatar {
      max-width: 20%;
    }
  }
}
