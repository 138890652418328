@import "./settings";
@import "./theme/golf-digest/mixins";

@import "./vendor";

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  & > #root {
    // sass-lint:disable-line no-ids
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    ng-transclude {
      width: 100%;
      height: 100%;
    }

    #app-init {
      // sass-lint:disable-line no-ids
      width: 100%;
      height: 100%;
      overflow: hidden;

      & > .app-main {
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > .app-container {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }
    }
    :has(.non-scrollable) {
      .app-view {
        padding: 0 !important;
      }
    }

    .app-view {
      width: 100%;
      // height: 100%;
      margin: 7rem 0;
      padding-bottom: 20px;
    }
  }

  .rounded {
    border-radius: 8px !important;
  }

  i {
    font-style: normal;
  }

  .browser-support ~ #root {
    // sass-lint:disable-line no-ids
    height: calc(100vh - 88px);

    @media (max-width: 518px) {
      height: calc(100vh - 108px);
    }

    @media (max-width: 347px) {
      height: calc(100vh - 126px);
    }

    @media (max-width: 329px) {
      height: calc(100vh - 150px);
    }

    @media (max-width: 313px) {
      height: calc(100vh - 170px);
    }
  }
}

a:visited {
  text-decoration: none !important;
}

ul {
  &.breadcrumb {
    display: inline-block;
    margin: 1rem 0;
    padding: 0;
    clear: both;

    & > li {
      display: inline-block;
      padding: 0 0 0 0.25rem;
      float: left;
      cursor: pointer;

      & > a {
        text-decoration: none;
      }

      &:first-of-type {
        padding: 0;
        clear: left;
      }

      &:last-of-type {
        clear: right;
        cursor: text;
      }
    }
  }
}

// import legacy styles
// @import "~app/legacy/app/styles/app";

// import theme
@import "./theme/tables";
@import "./theme/golf-digest/main";

#toast-portal {
  // sass-lint:disable-line no-ids
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 0;
  padding: 0 10px;
  box-sizing: border-box;

  @include desktop-only {
    padding: 0 20px;
  }

  & > .toast-box {
    display: none;

    &.show {
      display: block;
    }
  }
}

:root {
  --fc-border-color: #f4f4f4;
  --fc-today-bg-color: white;
}

.fc-scrollgrid-section-header {
  background-color: #fff;
  overflow: hidden;
  border: 0px !important;
}

.fc {
  .fc-timegrid-slot {
    height: 4.5rem;
  }
}

.fc-col-header-cell {
  border: none !important;
}

.fc-timegrid-axis {
  border: none !important;
}

.fc-event-main {
  border: none !important;
}

.fc-timegrid-col {
  background-color: #fff;
}

a.fc-col-header-cell-cushion {
  cursor: default;
}

.fc-theme-standard .fc-timegrid colgroup,
.fc-theme-standard .fc-timegrid td:first-child,
.fc-theme-standard .fc-timegrid thead td,
.fc-theme-standard .fc-timegrid thead th {
  border: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  vertical-align: top !important;
}

.fc-timegrid-slot-label {
  vertical-align: top !important;
  top: -6px !important;
  position: relative;
}

.fc-today {
  background-color: white !important;
}

th {
  .fc-scroller-harness {
    div.fc-scroller {
      overflow-y: hidden !important;
    }
  }
}

// Common styles --------------------------------------------------
// sass-lint:disable no-important
@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

.right {
  float: right !important;
}

.left {
  float: left !important;
}

.righted {
  text-align: right !important;
}

.lefted {
  text-align: left !important;
}

.centered {
  text-align: center !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.nowrap {
  white-space: nowrap !important;
}

.hide {
  display: none;
}

.normal {
  font-weight: normal !important;
}

.bold {
  font-weight: bold !important;
}

// sass-lint:enable

.in-front-of {
  z-index: 10000;
}

/* Sidebar filter
-------------------------------------------------- */
.sidebar-filter {
  z-index: 1 !important; // sass-lint:disable-line no-important

  select {
    width: 100%;
  }

  .btn {
    border-radius: 8px !important;

    &.filter-submit {
      width: 90px;
    }
  }

  h5 {
    margin-top: 0;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .datepicker {
    width: 167px;
  }
}

@media (min-width: 1200px) {
  .sidebar-filter {
    .datepicker {
      width: 217px;
    }
  }
}

@media (max-width: 979px) and (min-width: 768px) {
  .sidebar-filter {
    .datepicker {
      width: 113px;
    }
  }
}

#wrap {
  // sass-lint:disable-line no-ids
  width: 100vw;
  height: calc(100vh - 35px);
  padding: 56px 0 0;
  box-sizing: border-box;
  overflow: hidden;

  // @media (max-width: 979px) and (min-width: 768px) {
  //   height: calc(100vh - 83px);
  // }

  & > .navbar {
    @include brand-gradient;
    position: relative;
    width: 100vw;
    height: 56px;
    margin: -56px 0 0;

    & > .navbar-inner {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      background-image: initial;

      & > a {
        display: inline-block;
        padding: 10px;
        text-decoration: none;

        & > img {
          height: 36px;
        }
      }
    }
  }

  & > .container {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;

    & > div {
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  &.public-wrap {
    & > .container {
      height: 100%;
      padding: 0;
      text-align: left;

      & > div {
        height: 100%;
        padding: 30px 10px;
        box-sizing: border-box;
      }
    }

    .back-button {
      position: fixed;
      top: 20px;
      left: 20px;
      padding: 15px;
      border: 1px solid #039be5;
      border-radius: 50px;
      background-color: #039be5;
      box-shadow: 1px 1px 10px -5px #333;
      color: #fff;
      z-index: 1000;

      & > span {
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
        border: 3px solid #fff;
        border-right-color: transparent;
        border-bottom-color: transparent;
        transform: rotate(-45deg);
        cursor: pointer;

        &:before {
          content: "\00a0";
          display: block;
          width: 23px;
          height: 0;
          border: 3px solid #fff;
          border-right: 0;
          border-left: 0;
          border-bottom: 0;
          transform: rotate(45deg) translate(2px, 7px);
        }
      }
    }

    &.hide-nav {
      width: 100%;
      height: 100%;
      padding: 0;

      & > .navbar {
        display: none;
      }

      & ~ .footer {
        display: none;
      }
    }
  }
}

/* Application footer
-------------------------------------------------- */
#footer {
  // sass-lint:disable-line no-ids
  position: relative;
  border-top: 1px solid #ddd;
  background-color: #f5f5f5;
  z-index: 5;

  .footer-inner {
    margin: 10px 50px 10px 15px;
  }

  ul {
    &.footer-menu {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-left: 10px;
        float: left;
      }
    }
  }

  p {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .footer-inner {
    padding: 0 20px;
  }
}

/* Application styles
-------------------------------------------------- */
#trial-info {
  // sass-lint:disable-line no-ids
  width: inherit;
  margin-bottom: 9px;
  padding: 12px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;

  a {
    color: #e54700;
    text-decoration: none;
  }
}

#main-menu {
  // sass-lint:disable-line no-ids
  position: fixed;
}

#main-menu,
// sass-lint:disable-line no-ids
.sidebar-filter {
  width: inherit;
}

.popover {
  z-index: 999999;
}

@media (max-width: 767px) {
  .tags-wrapper {
    grid-template-columns: 1fr !important;
  }

  .help-video {
    .help-video-icon {
      width: 35px;
      font-size: 35px;
    }
  }
}

@media (max-width: 767px) {
  .alert-top {
    margin-bottom: 0;
    border-radius: 0;
    text-align: center;
  }
}

.hl {
  background-color: #ffde00 !important; // sass-lint:disable-line no-important
  // color: black !important; // sass-lint:disable-line no-important
}

.loading {
  display: block;
  width: 100%;
  height: 100%;
  background: #fff url("/images/loading.gif") no-repeat center;

  &.wide,
  &.infinite-scroll {
    width: 100%;
    height: 32px;
    margin-bottom: 20px;
    background-color: transparent;
  }
}

.processing {
  padding: 10%;
  font-size: 19px;
  line-height: 1.4em;
  text-align: center;
}

.loader {
  width: 32px;
  height: 32px;
  margin: 0 10px;
}

.small-loader {
  width: 20px;
  height: 20px;
  margin: 5px;
  padding: 0;
  line-height: 17px;
  vertical-align: top;
}

.icon {
  margin-left: 5px;
  cursor: pointer;
}

.help {
  color: #b94a48;
}

.v-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  height: 72px;
  background: url("/images/playOverlaySmall.png") no-repeat center;
}

.video-player-preview {
  img {
    max-height: 480px;
  }
}

.mejs-mediaelement {
  video {
    width: 100%;
    height: 100%;
  }
}

.mejs-overlay-small-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -23px 0 0 -25px;
  background: url("/images/smallplay.png") no-repeat;

  &:hover {
    background-position: 0 -50px;
  }
}

// TODO: video-element can be removed after angular update
.video-container,
.video-element {
  position: relative;
  text-align: center;
  cursor: pointer;

  .play_video {
    // sass-lint:disable-line class-name-format
    &:hover {
      .mejs-overlay-button {
        background-position: 0 -100px;
      }
    }
  }
}

.audio-element {
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
  background: #000;
  cursor: pointer;

  .play-button {
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 16px;
    height: 16px;
    background: url("/images/controls.svg") no-repeat;
  }

  .seek {
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
    left: 30px;
    height: 10px;
    background: #333;
    background-color: rgba(50, 50, 50, 0.8);
    // sass-lint:disable no-duplicate-properties no-vendor-prefixes
    background-image: -webkit-gradient(
      linear,
      0% 0%,
      0% 100%,
      from(rgba(30, 30, 30, 0.8)),
      to(rgba(60, 60, 60, 0.8))
    );
    background-image: -webkit-linear-gradient(
      top,
      rgba(30, 30, 30, 0.8),
      rgba(60, 60, 60, 0.8)
    );
    background-image: -moz-linear-gradient(
      top,
      rgba(30, 30, 30, 0.8),
      rgba(60, 60, 60, 0.8)
    );
    background-image: -o-linear-gradient(
      top,
      rgba(30, 30, 30, 0.8),
      rgba(60, 60, 60, 0.8)
    );
    background-image: -ms-linear-gradient(
      top,
      rgba(30, 30, 30, 0.8),
      rgba(60, 60, 60, 0.8)
    );
    background-image: linear-gradient(
      rgba(30, 30, 30, 0.8),
      rgba(60, 60, 60, 0.8)
    );
    // sass-lint:enable
  }
}

.tags {
  .tag {
    margin-right: 3px;
    padding: 1px 3px;
    border-radius: 3px;
  }
}

.tags-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  .tags-container {
    @include flexbox;
    @include flex-dir(row);
    // @include flex-just(space-between);
    @include flex-ali(center);

    background-color: #fff;
    // padding: 20px;
    border-radius: 12px;

    .tag-info {
      @include flexbox;
      @include flex-dir(row);
      @include flex-just(space-between);
      width: 90%;

      & > .tags-title {
        font-weight: 700;
        font-size: 1rem;
        color: #000;
      }

      .options {
        text-decoration: none;
        color: #000;
      }
    }

    & > .tag-logo {
      @include flexbox;
      @include flex-just(center);
      @include flex-ali(center);
      margin-right: 15px;

      min-width: 4rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: #f4f4f4;

      & > i {
        font-size: 20px;
        color: #000;
      }
    }
  }
}

.window {
  padding: 20px 0;
}

.panel_container {
  padding: 0 !important;

  table {
    th {
      padding: 20px;
    }

    td {
      padding: 14px 20px;

      label {
        display: flex;
        align-items: center;
      }
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    td,
    th {
      border-top: 0;
      vertical-align: middle;
    }
  }

  .controls {
    padding: 0 20px 20px 20px;
  }
}

table {
  &.table-striped {
    td,
    th {
      border-top: 0;
      vertical-align: middle;
    }
  }

  .item-name {
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
  }
}

.mejs-poster {
  overflow-y: hidden;
}

.store-buttons {
  margin-left: 0;
  padding-bottom: 15px;
}

.store-btn {
  display: block;
  width: 50%;
  height: 44px;
  float: left;
  box-sizing: border-box;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.store-btn-left {
  padding-right: 5px;
}

.store-btn-right {
  padding-left: 5px;
}

.checkbox-list {
  color: #000;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;

  input {
    &[type="checkbox"] {
      margin-top: 0;
      margin-right: 0.5em;
    }
  }
}

.other-segments-label {
  color: #8c8c8c;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

.post-form-media-preview {
  .datafile-thumbnail {
    img {
      margin: 18px;
    }
  }
}

.select-mini {
  width: 100px;
  height: 20px;
  font-size: 11px;
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important; // sass-lint:disable-line no-important
}

.show-more {
  margin: 10px 0;
  text-align: center;
}

.visible {
  display: block;
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.always-show-scroll {
  overflow-y: scroll;
}

.text-center {
  text-align: center;
}

input {
  &[type="number"] {
    &.nospinner {
      &::-webkit-outer-spin-button,
      // sass-lint:disable-line no-vendor-prefixes
      &::-webkit-inner-spin-button {
        // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
      }
    }
  }
}

.text-gray {
  color: #bebebe;
  font-size: 13px !important; // sass-lint:disable-line no-important
}

.label-error {
  background-color: $error-color;
}

.mejs-container {
  overflow: hidden;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-05 {
  margin-bottom: 7.5px;
}

.width-auto {
  width: auto;
}

.classNameByType {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // gap: 16px;
  color: #000;
}

.pdf {
  border-color: #e54500;
}

.doc,
.docx {
  border-color: #5c80e2;
}

.ppt,
.pptx {
  border-color: #ff5722;
}

.xls,
.xlsx {
  border-color: #69c97e;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 0.75px !important;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.mobile-column {
  @include mobile-only {
    flex-direction: column !important;
    gap: 10px !important;

    & > * {
      width: 100%;
    }
  }
}

.data-vjs-player {
  width: 100%;
  height: 480px;
  position: relative;
  box-sizing: border-box;

  .media-video {
    height: 100%;
    box-sizing: border-box;
    position: relative;
    background-color: #fff !important;
  }

  .edit-page-vjs {
    height: 100% !important;
    width: 100%;
    box-sizing: border-box;
    position: relative;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.text-danger {
  color: #e54500 !important;
}

.flag-select {
  padding-bottom: 0px !important;
}

.country-select {
  color: #363636 !important;
  width: 100%;
  height: 44px;
  border-radius: 7px;
  cursor: pointer;

  span {
    width: unset !important;
    height: unset !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    position: unset !important;
    width: 18px;
    height: 18px;
    top: 0;
    max-width: unset !important;
  }

  .flag-select__btn {
    // padding: 10px 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .flag-select__option {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ul {
    width: 100%;
    top: 40px;

    li {
      padding: 10px 20px 10px 8px !important;
    }
  }

  & > button {
    outline: none;
    font-weight: normal;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0.7rem 1rem;
  }

  ::before {
    top: 0;
    left: 0;
  }

  ::after {
    right: 20px;
    position: absolute;
  }
}

.country-select-error {
  border: 1px #e54500 solid;
}

.isNotReady {
  background-image: url("/images/default_wave.svg");
}

.dot {
  width: 3px;
  height: 3px;
  background-color: #8c8c8c;
  border-radius: 100%;
  margin: 0 12px;
}

// todo: fix this code
.ico {
  margin-bottom: 5px;
}

.fc-timegrid-col:nth-of-type(2) {
  .multi-coach-now-indicator-line {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 15px !important;
      height: 15px !important;
      background-color: red !important;
      border-radius: 50%;
      z-index: 1;
    }
  }
}

.fc-timegrid-now-indicator-arrow {
  display: none !important;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  vertical-align: top;
  padding-right: 20px;
  padding-left: 12px;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: left;
}

.fc-timegrid-slot-label {
  vertical-align: top;
  top: -6px;
  position: relative;
}

.fc-non-business {
  background-color: #f4f4f4;
  background-image: repeating-linear-gradient(
      135deg,
      rgba(150, 150, 150, 0.1),
      rgba(150, 150, 150, 0.1) 2px,
      transparent 2px,
      transparent 12px
    ),
    repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 59px,
      #dddddd 60px
    ) !important;
  border-right: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fc-timegrid-slot {
  height: 60px !important;
}

.fc-timegrid-slot-label::after {
  content: "";
  position: absolute;
  top: 5px;
  width: 12px;
  height: 1px;
  left: 55px;
  background-color: var(--fc-border-color);
  z-index: 1;
  margin-left: 0px;
}

.now-indicator-line {
  position: relative;
  height: 1px;
  background-color: red;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px !important;
    height: 15px !important;
    background-color: red !important;
    border-radius: 50%;
    z-index: 1;
  }
}

@media (max-width: 450px) {
  .fc .fc-col-header-cell-cushion {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  th.fc-timegrid-axis {
    display: none !important;
  }

  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    padding-left: 21px;
  }

  .fc-timegrid-slot-label::after {
    width: 16px;
  }
}

.react-datepicker-popper {
  z-index: 10 !important;
}

// .btn-primary,
// .btn-outline-primary,
// .btn-secondary,
// .btn-outline-secondary,
// .btn-secondary-filled {
//   border-radius: 22px !important;
// }
