$grid-item-border-color: $border-color !default;
$grid-item-active-border-color: $active-border-color !default;
$grid-item-padding: $element-padding !default;
$grid-item-title-size: 1.1rem !default;
$grid-item-sub-text-color: $muted-text-color !default;

.grid-list {
  &.grid-gutters {
    margin-bottom: -$grid-cell-gutter;

    .cell {
      margin-bottom: $grid-cell-gutter;
    }
  }

  .grid-item {
    display: flex;
    align-items: center;
    padding: $grid-item-padding;
    background-color: #fff;
    border-radius: 12px;
    color: #000;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
      border-color: $grid-item-active-border-color;
    }
  }
}
