$icomoon-font-family: "coachnow-icons" !default;
$icomoon-font-path: "/fonts/coachnow-icons" !default;
$ico-open: "\e946";
$ico-back: "\e944";
$ico-forward: "\e945";
$ico-resend: "\e943";
$ico-connections: "\e940";
$ico-user-add-o: "\e941";
$ico-dollar: "\e942";
$ico-search: "\e93f";
$ico-lock-plus: "\e93e";
$ico-options: "\e93d";
$ico-leave: "\e93c";
$ico-groups-o: "\e937";
$ico-groups: "\e938";
$ico-home: "\e939";
$ico-home-o: "\e93a";
$ico-pause: "\e93b";
$ico-retry: "\e936";
$ico-lock-o: "\e92c";
$ico-lock: "\e934";
$ico-shield: "\e935";
$ico-coach: "\e933";
$ico-chevron: "\e931";
$ico-x: "\e932";
$ico-check-o: "\e92f";
$ico-check: "\e930";
$ico-copy: "\e929";
$ico-info: "\e92a";
$ico-link: "\e92b";
$ico-menu: "\e92d";
$ico-read: "\e92e";
$ico-add: "\e900";
$ico-camera-o: "\e901";
$ico-camera: "\e902";
$ico-cog: "\e903";
$ico-document-o: "\e904";
$ico-document: "\e905";
$ico-feed-o: "\e906";
$ico-feed: "\e907";
$ico-fist-o: "\e908";
$ico-fist: "\e909";
$ico-funnel: "\e90a";
$ico-library-o: "\e90b";
$ico-library: "\e90c";
$ico-mail-o: "\e90d";
$ico-mail: "\e90e";
$ico-media: "\e90f";
$ico-microphone-o: "\e910";
$ico-microphone: "\e911";
$ico-note: "\e912";
$ico-notifications-o: "\e913";
$ico-notifications: "\e914";
$ico-pencil-o: "\e915";
$ico-pencil: "\e916";
$ico-phone-o: "\e917";
$ico-phone: "\e918";
$ico-play: "\e919";
$ico-reply-o: "\e91a";
$ico-reply: "\e91b";
$ico-signout: "\e91c";
$ico-tags-add: "\e91d";
$ico-tags-o: "\e91e";
$ico-tags: "\e91f";
$ico-team: "\e920";
$ico-trash: "\e921";
$ico-tspace-o: "\e922";
$ico-tspace: "\e923";
$ico-user-add: "\e924";
$ico-user-o: "\e925";
$ico-user: "\e926";
$ico-video-o: "\e927";
$ico-video: "\e928";
$ico-time: "\e947";
$ico-heart: "\e948";
$ico-templates: "\e949";
$ico-squared-mail: "\e94a";
$ico-new-phone: "\e94b";
$ico-calendar: "\e94c";
$ico-clock: "\e94d";
$ico-map-marker: "\e94e";
$ico-globe: "\e94f";
$ico-calendar-x: "\e950";
$ico-edit-outline: "\e951";
$ico-trash-outline: "\e952";
$ico-grouped-people: "\e953";
$ico-dual-toggle: "\e954";
$ico-program: "\e955";
$ico-ticket: "\e956";
$ico-facility: "\e957";
$ico-dollar-circle: "\e958";
$ico-home-menu: "\e959";
