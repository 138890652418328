@import "variables";

@font-face {
  font-family: "coachnow-icons";
  font-style: normal;
  font-weight: normal;
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?h4i4vu")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?h4i4vu")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?h4i4vu##{$icomoon-font-family}")
      format("svg");
}

.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important; // sass-lint:disable-line no-important
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
}

.ico.dark {
  color: #000000 !important;
}

.ico.gray {
  color: #8c8c8c;
}

.ico.bold {
  font-size: 24px;
}

.ico-open {
  &:before {
    content: $ico-open;
  }
}

.ico-back {
  &:before {
    content: $ico-back;
  }
}

.ico-forward {
  &:before {
    content: $ico-forward;
  }
}

.ico-resend {
  &:before {
    content: $ico-resend;
  }
}

.ico-connections {
  &:before {
    content: $ico-connections;
  }
}

.ico-user-add-o {
  &:before {
    content: $ico-user-add-o;
  }
}

.ico-dollar {
  &:before {
    content: $ico-dollar;
  }
}

.ico-search {
  &:before {
    content: $ico-search;
  }
}

.ico-lock-plus {
  &:before {
    content: $ico-lock-plus;
  }
}

.ico-options {
  &:before {
    content: $ico-options;
  }
}

.ico-leave {
  &:before {
    content: $ico-leave;
  }
}

.ico-groups-o {
  &:before {
    content: $ico-groups-o;
  }
}

.ico-groups {
  &:before {
    content: $ico-groups;
  }
}

.ico-home {
  &:before {
    content: $ico-home;
  }
}

.ico-home-o {
  &:before {
    content: $ico-home-o;
  }
}

.ico-pause {
  &:before {
    content: $ico-pause;
  }
}

.ico-retry {
  &:before {
    content: $ico-retry;
  }
}

.ico-lock-o {
  &:before {
    content: $ico-lock-o;
  }
}

.ico-lock {
  &:before {
    content: $ico-lock;
  }
}

.ico-shield {
  &:before {
    content: $ico-shield;
  }
}

.ico-coach {
  &:before {
    content: $ico-coach;
  }
}

.ico-chevron {
  &:before {
    content: $ico-chevron;
  }
}

.ico-x {
  &:before {
    content: $ico-x;
  }
}

.ico-check-o {
  &:before {
    content: $ico-check-o;
  }
}

.ico-check {
  &:before {
    content: $ico-check;
  }
}

.ico-copy {
  &:before {
    content: $ico-copy;
  }
}

.ico-info {
  &:before {
    content: $ico-info;
  }
}

.ico-link {
  &:before {
    content: $ico-link;
  }
}

.ico-menu {
  &:before {
    content: $ico-menu;
  }
}

.ico-read {
  &:before {
    content: $ico-read;
  }
}

.ico-add {
  &:before {
    content: $ico-add;
  }
}

.ico-camera-o {
  &:before {
    content: $ico-camera-o;
  }
}

.ico-camera {
  &:before {
    content: $ico-camera;
  }
}

.ico-cog {
  &:before {
    content: $ico-cog;
  }
}

.ico-document-o {
  &:before {
    content: $ico-document-o;
  }
}

.ico-document {
  &:before {
    content: $ico-document;
  }
}

.ico-feed-o {
  &:before {
    content: $ico-feed-o;
  }
}

.ico-feed {
  &:before {
    content: $ico-feed;
  }
}

.ico-fist-o {
  &:before {
    content: $ico-fist-o;
  }
}

.ico-fist {
  &:before {
    content: $ico-fist;
  }
}

.ico-funnel {
  &:before {
    content: $ico-funnel;
  }
}

.ico-library-o {
  &:before {
    content: $ico-library-o;
  }
}

.ico-library {
  &:before {
    content: $ico-library;
  }
}

.ico-mail-o {
  &:before {
    content: $ico-mail-o;
  }
}

.ico-mail {
  &:before {
    content: $ico-mail;
  }
}

.ico-media {
  &:before {
    content: $ico-media;
  }
}

.ico-microphone-o {
  &:before {
    content: $ico-microphone-o;
  }
}

.ico-microphone {
  &:before {
    content: $ico-microphone;
  }
}

.ico-note {
  &:before {
    content: $ico-note;
  }
}

.ico-notifications-o {
  &:before {
    content: $ico-notifications-o;
  }
}

.ico-notifications {
  &:before {
    content: $ico-notifications;
  }
}

.ico-pencil-o {
  &:before {
    content: $ico-pencil-o;
  }
}

.ico-pencil {
  &:before {
    content: $ico-pencil;
  }
}

.ico-phone-o {
  &:before {
    content: $ico-phone-o;
  }
}

.ico-phone {
  &:before {
    content: $ico-phone;
  }
}

.ico-play {
  &:before {
    content: $ico-play;
  }
}

.ico-reply-o {
  &:before {
    content: $ico-reply-o;
  }
}

.ico-reply {
  &:before {
    content: $ico-reply;
  }
}

.ico-signout {
  &:before {
    content: $ico-signout;
  }
}

.ico-tags-add {
  &:before {
    content: $ico-tags-add;
  }
}

.ico-tags-o {
  &:before {
    content: $ico-tags-o;
  }
}

.ico-tags {
  &:before {
    content: $ico-tags;
  }
}

.ico-team {
  &:before {
    content: $ico-team;
  }
}

.ico-trash {
  &:before {
    content: $ico-trash;
  }
}

.ico-tspace-o {
  &:before {
    content: $ico-tspace-o;
  }
}

.ico-tspace {
  &:before {
    content: $ico-tspace;
  }
}

.ico-user-add {
  &:before {
    content: $ico-user-add;
  }
}

.ico-user-o {
  &:before {
    content: $ico-user-o;
  }
}

.ico-user {
  &:before {
    content: $ico-user;
  }
}

.ico-video-o {
  &:before {
    content: $ico-video-o;
  }
}

.ico-video {
  &:before {
    content: $ico-video;
  }
}

.ico-time {
  &:before {
    content: $ico-time;
  }
}

.ico-heart {
  &:before {
    content: $ico-heart;
  }
}

.ico-templates {
  &:before {
    content: $ico-templates;
  }
}

.ico-squared-mail {
  &:before {
    content: $ico-squared-mail;
  }
}

.ico-new-phone {
  &:before {
    content: $ico-new-phone;
  }
}

.ico-calendar {
  &:before {
    content: $ico-calendar;
  }
}

.ico-clock {
  &:before {
    content: $ico-clock;
    margin-top: -3px;
  }
}

.ico-map-marker {
  &:before {
    content: $ico-map-marker;
  }
}

.ico-globe {
  &:before {
    content: $ico-globe;
  }
}

.ico-calendar-x {
  &:before {
    content: $ico-calendar-x;
  }
}

.ico-edit-outline {
  &:before {
    content: $ico-edit-outline;
  }
}

.ico-trash-outline {
  &:before {
    content: $ico-trash-outline;
  }
}

.ico-dual-toggle {
  &:before {
    content: $ico-dual-toggle;
  }
}

.ico-grouped-people-outline {
  &:before {
    content: $ico-grouped-people;
  }
}

.ico-program {
  &:before {
    content: $ico-program;
  }
}

.ico-ticket {
  &:before {
    content: $ico-ticket;
  }
}

.ico-facility {
  &:before {
    content: $ico-facility;
  }
}

.ico-dollar-circle {
  &:before {
    content: $ico-dollar-circle;
  }
}

.ico-home-menu {
  &:before {
    content: $ico-home-menu;
  }
}
