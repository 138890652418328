$media-list-item-size: 11.875rem !default;
$media-list-item-detail-height: 2.5rem !default;
$media-list-item-details-color: rgba($black, 0.7) !default;
$media-list-item-details-text-color: $white !default;
$media-list-item-details-padding: 0.75rem 1rem !default;
$media-list-item-select-scale: 0.95 !default;
$media-list-item-info-padding: 0.35rem 0.55rem !default;

.media-list {
  overflow-x: hidden;
  overflow-y: auto;

  .media-list-item {
    background-color: $light-gray;
    background-position: center;
    background-size: cover;
    height: $media-list-item-size;
    border-radius: 16px;
    overflow: hidden;
    padding: 0;
    border: none;
    // margin-bottom: $element-margin;
    outline: none;
    position: relative;
    text-align: left;
    transition: transform $transition-time-fast ease-in;
    width: $media-list-item-size;

    &:hover {
      transform: scale($media-list-item-select-scale);
    }
  }

  .media-list-item-info {
    color: $white;
    line-height: 1px;
    padding: $media-list-item-info-padding;
    position: absolute;
    right: 0;
    top: 0;
  }

  .media-list-item-placeholder {
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


  }

  .selected-checkmark {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 24px;
    height: 24px;
    background: #E54500;
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .media-list-item-details {
    // background-color: $media-list-item-details-color;
    bottom: 0;
    // color: $media-list-item-details-text-color;
    color: #ffffff;
    // height: $media-list-item-detail-height;
    left: 0;
    // padding: $media-list-item-details-padding;
    padding: 8px;
    position: absolute;
    // transition: background-color $transition-time-fast ease-in;
  }

  .media-list-item-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .media-list-item-post-count, .media-list-item-label {
      padding: 0 8px;
    }

    & .media-list-item-post-count {
      font-size: 0.9rem;
      font-weight: 600;
      margin-top: 0.5rem;
      line-height: 17px;
      color: black;
    }

    .media-list-item-label {
      font-size: 0.9rem;
      line-height: 17px;
      color: #8C8C8C;
    }

  }


  .selected {
    &.media-list-item {
      border: 2px solid #E54500;
      transform: scale($media-list-item-select-scale);
    }


  }
}

.media-type-video {
  .media-list-item-info {
    background-color: $orange;
  }
}

.media-type-image {
  .media-list-item-info {
    background-color: $blue;
  }
}

.media-type-audio {
  .media-list-item-info {
    background-color: $orange;
  }
}

.media-type-doc {
  .media-list-item-info {
    background-color: $word-doc-blue;
  }
}

.media-type-xls {
  .media-list-item-info {
    background-color: $excel-doc-green;
  }
}

.media-type-ppt {
  .media-list-item-info {
    background-color: $power-point-doc-orange;
  }
}

.media-type-pdf {
  .media-list-item-info {
    background-color: $pdf-doc-red;
  }
}
