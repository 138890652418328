
.left-nav-layout {
  width: 100%;

  .nav-container {
    width: 280px;
    float: left;
    padding: 0 $element-margin / 2 0 0;
    box-sizing: border-box;
  }

  .content-container {
    width: 100%;
    float: left;
    padding: 0;
    box-sizing: border-box;

    @include desktop-only-large {
      width: calc(100% - 280px);
      padding: 0 0 0 $element-margin / 2;
    }
  }
}
