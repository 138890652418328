.csv-column-preview-container {
  margin: 0 auto;
  max-width: 30rem;
}

.csv-column-preview {
  @include border;
  background-color: $white;
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $element-padding;
}

table.csv-column-table { // scss-lint:disable QualifyingElement
  width: 100%;

  tr {
    @include border;
  }

  td {
    padding: $element-padding / 2 $element-padding;
  }
}
