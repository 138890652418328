$tag-pills-icon-width: 1rem;
$tag-pills-icon-height: 0.65rem;

.tag-pills {
  $tag-pill-body-width: 0.75 * $tag-pills-icon-width;
  $tag-pill-point-width: $tag-pills-icon-width - $tag-pill-body-width;

  .pill-search-icon {
    background-color: $dark-gray;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    display: inline-block;
    height: $tag-pills-icon-height;
    margin-left: 0.25rem;
    margin-right: $element-margin / 2 + $tag-pill-point-width;
    position: relative;
    width: $tag-pill-body-width;

    &::after {
      border-bottom: $tag-pills-icon-height / 2 transparent solid;
      border-left: $tag-pill-point-width $dark-gray solid;
      border-top: $tag-pills-icon-height / 2 transparent solid;
      box-sizing: border-box;
      content: "";
      height: $tag-pills-icon-height;
      position: absolute;
      right: -$tag-pill-point-width;
    }
  }
}
