$dropdown-menu-max-height: 400px;

.dropdown {
  position: relative;

  .inline-flex {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 0.75rem;
    min-width: 15rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    p {
      margin: 0;
    }
  }

  & > .filter-dropdown-container {
    overflow: visible !important;
  }

  & > .dropdown-menu {
    @include shadow;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: $element-margin / 4;
    margin-bottom: 1rem;
    padding: $element-padding;
    float: left;
    background-color: $white;
    color: $text-color;
    font-size: $font-size;
    z-index: 100;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;

    &.pull-right {
      right: 4px;
      left: auto;
    }

    @media screen and(max-width: 448px) {
      &.pull-right {
        right: 3px;
        padding: 12px;
        left: auto;
      }

      &.w-2 {
        width: max-content;
      }
      &.max-w-2 {
        width: max-content;
        max-width: 270px;
      }
    }

    .scrollable {
      max-height: $dropdown-menu-max-height;
      overflow: auto;
    }

    .checkbox-label {
      color: $dark-text-color;
    }
  }

  ul {
    &.dropdown-menu {
      @include list-reset;
      margin-top: $element-margin / 4;
      margin-bottom: $element-margin;
      padding: 0;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;

      %dropdown-menu-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        width: 100%;
        padding: 20.75px;
        text-align: left;
        white-space: nowrap;
      }

      li {
        // transition: background-color 0.2s ease-out;
        background-color: $white;

        & > a {
          @extend %dropdown-menu-item;
          box-sizing: border-box;
          text-decoration: none;
        }

        & > button {
          @include button-reset;
          @extend %dropdown-menu-item;
        }

        & > a,
        & > button {
          color: rgba(0, 0, 0, 0.647);
          font-size: 1rem;
        }

        & > .text-danger {
          color: rgba(
            229,
            69,
            0,
            0.847
          ) !important; // sass-lint:disable-line no-important
        }

        &:hover,
        &:active {
          background-color: $light-gray;

          & > a,
          & > button {
            color: rgba(0, 0, 0, 0.847);
          }

          & > .text-danger {
            color: rgba(
              229,
              69,
              0,
              1
            ) !important; // sass-lint:disable-line no-important
          }
        }
      }
    }
  }

  &.open {
    & > .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-menu-tabs {
  margin-top: -$element-margin;
  margin-right: -$element-margin;
  // margin-bottom: $element-margin;
  margin-left: -$element-margin;
  border-bottom: 1px solid #dddddd;
}

.content-dropdown-holder {
  position: relative;
  width: 100%;
}

.content-card-dropdown {
  position: absolute !important;
  top: 32px;
  right: 20px;

  & > button {
    border: none;
  }
}
