// sass-lint:disable no-qualifying-elements force-pseudo-nesting force-attribute-nesting
@mixin style-field {
  select,
  input:not([type]),
  input[type="email"],
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  textarea {
    @content;
  }
}

input[type="number"] {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}
// sass-lint:enable

@mixin theme-field($color) {
  border-color: $color;
}

@mixin animate-field($property: null) {
  transition-duration: 0.2s;
  transition-property: background-color, border-color, opacity, $property;
  transition-timing-function: ease-out;
}

%field {
  @include animate-field;
  width: 100%;
  height: 44px;
  padding: $control-vertical-padding $control-horizontal-padding;
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: rgba(0, 0, 0, 0.647);
  outline: 0;
  opacity: 1;
  font-size: 16px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes

  &::placeholder {
    color: #8c8c8c;
  }
}

@mixin field {
  @extend %field;
}

@include style-field {
  @include field;

  &:not(:disabled):not([readonly]) {
    //sass-lint:disable-line force-pseudo-nesting
    &:focus {
      @include theme-field($active-color);
    }

    .error &,
    &.error,
    &.ng-invalid {
      @include theme-field($danger-color);
    }
  }

  &:disabled {
    @include disable;
    background-color: $disabled-color;
  }

  &[readonly] {
    background-color: $disabled-color;
  }
}

.stripe-input {
  @include field;

  &.focus {
    @include theme-field($active-color);
  }

  &.disabled {
    @include disable;
    background-color: $disabled-color;
  }
}

select {
  padding-right: 3 * $element-padding;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAASCAYAAACnxdXaAAAAAXNSR0IArs4c6QAAAUVJREFUSA21lEFugzAQRTHZokbqQXqFbnIkDsAe2HCh9gKRmotkm6CGOP+DHdkwgCFhFphhxv/xbYyqquqjrutfpdQ5TdNDtFHkef4Dxr5pmm+F5ITky7IAVvb+HWOWZXGSJP/QisGJtNZ/MZKLK14UhXbzV+5dIHUA5HBtXUmgVx33gaQhNHRjOo0kgPQi7A0JOFJ2SZ3+Fsjc2z8JJL2QIzS4JbAsyxsKrSHT8AQydwvWsben0osYocEQAhxA+YBrjmEVGA4bzHWNeA6pz/CWt3vUXeHw3q9PLbXQLwKpPgplURCyW8DyM4S+USAnTULZIAh6YNS9rcCUSSA1Z6FsGgOvAQZDJTC+1DN+a5+smZh1aBuDnNpmOOt/nbYUDOSERVBOEMCLgNRwzxTz2cCx2aGJx4lxN+e6y7a8mj/PcUvG27UfqlCaDMyhQZwAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: calc(100% - #{$element-padding}) center;
  background-size: 14px 8px;
}
