
// sass-lint:disable no-important
.force-hidden {
  display: none !important;
  visibility: hidden !important;
}

.mobile-only {
  @include desktop-only {
    display: none !important;
  }
}

.desktop-only {
  @include mobile-only {
    display: none !important;
  }
}

.mobile-only-large {
  @include desktop-only-large {
    display: none !important;
  }
}

.desktop-only-large {
  @include mobile-only-large {
    display: none !important;
  }
}
