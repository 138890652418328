
.team-wrap {
  display: flex;
  flex-direction: column;
  margin: 0 (-$grid-cell-gutter / 2);

  @include desktop-only-large {
    flex-direction: row;

    .team-wrap-nav {
      flex: 0 0 auto;
      width: $team-group-nav-width;
    }
  }

  .team-wrap-nav {
    padding: 0 $grid-cell-gutter / 2;
  }

  .team-wrap-content {
    flex: 1 1 auto;
    padding: 0 $grid-cell-gutter / 2;

    @include desktop-only-large {
      width: calc(100% - 310px);
    }
  }
}
