$modal-backdrop-color: rgba(0, 0, 0, 0.7);
$modal-dialog-size: 40rem;
$modal-dialog-sizes: (
  'sm': 25rem,
  'lg': 60rem
);

.modal-window {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 $element-padding;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
}

.modal-backdrop {
  background-color: $modal-backdrop-color;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal-dialog {
  border-radius: 16px;
  margin: 3rem auto;
  max-width: $modal-dialog-size;
  overflow: hidden;
  transition: margin 0.2s ease-in;
  width: 100%;

  @include mobile-only {
    margin: $element-margin auto;
  }

  @each $key, $size in $modal-dialog-sizes {
    &.modal-dialog-#{$key} {
      max-width: $size;
    }
  }
}

.modal-header {
  // @include brand-gradient;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  padding: 28px 45px 9.37px 20px;
  position: relative;
  text-align: left;

  .dismiss {
    color: #CECECE;
    font-weight: bold;
    opacity: 1;
    padding: 0.625 * $element-padding $element-padding;
    position: absolute;
    right: 0;
    top: 15px;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: $hover-opacity;
    }

    &.ico {
      line-height: 1.5;
    }
  }

  button.dismiss { // scss-lint:disable QualifyingElement
    @include button-reset();
    @extend .dismiss;
  }
}

.modal-body {
  background-color: $white;
  padding: $element-padding;

  > .tabs {
    margin-bottom: $element-padding;
    margin-left: -$element-padding;
    margin-right: -$element-padding;
    margin-top: -$element-padding;
  }

  section {
    margin-left: -$element-padding;
    margin-right: -$element-padding;
    padding: $element-padding;

    &.highlight {
      background-color: $light-gray;
    }

    &.footnote {
      border-top: 1px $border-color solid;
    }
  }
}

.modal-footer {
  background-color: #fff;
  // border-top: 1px $border-color solid;
  padding: $element-padding;

  & > button {
    border-radius: 8px!important;
  }
}
