// scss-lint:disable ImportantRule

$base-spacing: 2rem !default;
$spacing-steps: 4 !default;

@for $step from 0 to $spacing-steps + 1 {
  $spacing: $base-spacing * $step / 2;

  .m-#{$step} {
    margin: $spacing !important;
  }

  .mx-#{$step} {
    margin-left: $spacing !important;
    margin-right: $spacing !important;
  }

  .my-#{$step} {
    margin-bottom: $spacing !important;
    margin-top: $spacing !important;
  }

  .mt-#{$step} {
    margin-top: $spacing !important;
  }

  .mr-#{$step} {
    margin-right: $spacing !important;
  }

  .mb-#{$step} {
    margin-bottom: $spacing !important;
  }

  .ml-#{$step} {
    margin-left: $spacing !important;
  }

  .mb-#{$step}-sm {
    @include mobile-only {
      margin-bottom: $spacing !important;
    }
  }

  .mb-#{$step}-lg {
    @include desktop-only {
      margin-bottom: $spacing !important;
    }
  }

  .mt-#{$step}-sm {
    @include mobile-only {
      margin-top: $spacing !important;
    }
  }

  .mt-#{$step}-lg {
    @include desktop-only {
      margin-top: $spacing !important;
    }
  }

  .p-#{$step} {
    padding: $spacing !important;
  }

  .px-#{$step} {
    padding-left: $spacing !important;
    padding-right: $spacing !important;
  }

  .pt-#{$step} {
    padding-top: $spacing !important;
  }

  .pr-#{$step} {
    padding-right: $spacing !important;
  }

  .pb-#{$step} {
    padding-bottom: $spacing !important;
  }

  .pl-#{$step} {
    padding-left: $spacing !important;
  }
}

