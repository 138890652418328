$team-list-search-bar-width: 389px;

.teams-search {
  position: relative;
  max-width: $team-list-search-bar-width;
  height: 44px;

 & > input {
  border-radius: 8px;
  width: 100%;
  height: 42px;
  padding-left: 12px;
  border: none;
 }

  & > i {
    position: absolute;
    top: 10px;
    right: 9px;
    color: #8c8c8c;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 20px;
  }

}
