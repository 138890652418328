$text-colors: (
  "muted": $muted-text-color,
  "danger": $danger-color,
  "info": $info-color,
  "success": $success-color
) !default;

%anchor-text-color {
  transition: opacity 0.2s ease-in;
}

@each $name, $color in $text-colors {
  .text-#{$name} {
    color: $color !important; // scss-lint:disable ImportantRule
  }

  a.text-#{$name} {
    @extend %anchor-text-color;
  }
}

.text-bottom {
  vertical-align: bottom !important; // scss-lint:disable ImportantRule
}

.text-top {
  vertical-align: top !important; // scss-lint:disable ImportantRule
}

.text-middle {
  vertical-align: middle !important; // scss-lint:disable ImportantRule
}

.text-right {
  text-align: right !important; // scss-lint:disable ImportantRule
}

.text-left {
  text-align: left !important; // scss-lint:disable ImportantRule
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-nowrap {
  white-space: nowrap;
}

.text-small {
  font-size: $font-size-small;
}

.note {
  font-size: $font-size-small;
}
