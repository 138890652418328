$panel-background: $white !default;

.panel {
  background: $panel-background;
  border-radius: 12px;
  margin-bottom: $element-margin;
  padding: 20px;

  .invitations-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .activity-list-group-set {
      margin: 0;
      label {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
  .breadcrumbs {
    color: #8c8c8c;
  }
}

.panel-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  // margin-bottom: $element-margin;

  .panel-title {
    display: inline-block;
    margin: 0;
    color: #000;
  }
}

.panel-tabs {
  margin-bottom: -1.3rem;
  margin-left: -$element-padding;
  margin-right: -$element-padding;
  padding-top: $element-padding;
}

@media screen and (max-width: 448px) {
  .panel-header {
    .panel-title {
      font-size: 20px;
    }

    :last-child {
      .btn {
        margin-bottom: 0px !important;
        height: 100%;
        // padding: 0px 12px;
        font-size: 14px;
      }
    }
  }
}
