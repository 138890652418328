$checkbox-indicator-width: 2px;

.checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  label { // scss-lint:disable QualifyingElement
    @include border;
    border-radius: $border-radius;
    box-sizing: border-box;
    display: block;
    height: 1rem;
    transition: all 0.2s ease-out;
    width: 1rem;
  }

  input[type="checkbox"] { // scss-lint:disable QualifyingElement
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 1px;

    &:disabled + label { // scss-lint:disable QualifyingElement
      cursor: default;
      opacity: $disabled-opacity;
    }

    &:checked + label { // scss-lint:disable QualifyingElement
      background-color: $active-color;
      border-color: $active-color;

      &::before {
        border-bottom: $checkbox-indicator-width $white solid;
        border-left: $checkbox-indicator-width $white solid;
        content: "";
        display: block;
        height: $border-radius;
        position: absolute;
        transform: rotateZ(-45deg) translateX(-0.1rem) translateY(0.2rem);
        width: 0.55rem;
      }
    }

    &:not(:disabled) + label { // scss-lint:disable QualifyingElement
      &:hover {
        border-color: $active-color;
      }
    }
  }
}
