.invitations > .tab-content {
  $invitiation-item-padding: 15px;

  // scss-lint:disable SelectorDepth
  //  Must overide base style.
  a,
  a:hover {
    background-color: $white;
    border: 1px $border-color solid;
    padding: $invitiation-item-padding;

    .btn-toolbar {
      margin: {
        bottom: $invitiation-item-padding / 2;
        top: $invitiation-item-padding / 2;
      }
    }
  }
}

// scss-lint:disable SelectorDepth
//  Must overide base style.
.invitations .incoming {
  .accepted {
    &,
    &:hover {
      background-color: $white;
    }
  }
}
// scss-lint:enable SelectorDepth
