$mobile-breakpoint: 820px !default;
$smallest-mobile-breakpoint: 448px !default;
$mobile-breakpoint-large: 1150px !default;

@mixin mobile-only {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin smallest-mobile-only {
  @media (max-width: $smallest-mobile-breakpoint) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $mobile-breakpoint + 1) {
    @content;
  }
}

@mixin mobile-only-large {
  @media (max-width: $mobile-breakpoint-large) {
    @content;
  }
}

@mixin desktop-only-large {
  @media (min-width: $mobile-breakpoint-large + 1) {
    @content;
  }
}
