$import-complete-icon-width: 50px;

.import-complete-status {
  align-items: center;
  display: flex;

  .import-complete-status-icon {
    margin: 0 $element-margin;
    min-width: $import-complete-icon-width;
  }

  .import-complete-status-summary {
    flex: 0 1 auto;
  }
}
