$layout-widths: (
  1: 18rem,
  2: 26rem,
  3: 32rem,
) !default;

.full {
  width: 100%;
}

.full-sm {
  @include mobile-only {
    width: 100%;
  }
}

.max-h-sm {
  max-height: 41px;
}

@each $name, $width in $layout-widths {
  .w-#{$name} {
    width: 100%;
    max-width: $width;
  }

  .w-#{$name}-lg {
    @include desktop-only {
      width: 100%;
      max-width: $width;
    }
  }

  .w-#{$name}-sm {
    @include mobile-only {
      width: 100%;
      max-width: $width;
    }
  }

  .dropdown-menu {
    &.w-#{$name} {
      width: $width;
      max-width: none;
    }

    &.w-#{$name}-lg {
      @include desktop-only {
        width: $width;
        max-width: none;
      }
    }

    &.w-#{$name}-sm {
      @include mobile-only {
        width: $width;
        max-width: none;
      }
    }
  }
}
