$plus-icon-size: 1rem;

.plus-icon {
  @extend %coach-now-plus-sprite;
  background-color: $brand-primary-color;

  background-position: center center;

  background-repeat: no-repeat;
  background-size: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  height: $plus-icon-size;
  vertical-align: text-top;
  width: $plus-icon-size;
}
