
// Globals --------------------------------------------------

@function calcRems($pixels) {
  @return #{$pixels/15px}rem;
}

@function calcPixels($rem) {
  @return #{($rem/1rem)*15}px;
}

// text settings
$text-color: #5e5e5e;
$muted-text-color: #8c8c8c;
$font-size: calcRems(16px);
// $font-size-small: calcRems(14px);
// $font-size-tiny: calcRems(12px);
$line-height: 1.5;

// background colors
$bg-color: #f8f8f8;
$background-color: #f8f8f8;

// error colors
$error-color: #b22222;

// base color overrides
$white: #fff;

@import './theme/golf-digest/settings';
