
.f {
  @include flexbox;

  > * {
    @include flex(0, 0, auto);
  }
}

.f-middle {
  @include align-items(center);
}
