$black-transparent: rgba(0, 0, 0, 0);

.lightbox {
  display: block;
  height: 100%;
  margin: 0 (-$element-margin);
  position: relative;
}

.lightbox-top {
  background: linear-gradient(to bottom, $black, $black-transparent);
  padding: $element-padding / 2;
  text-align: right;
}

.lightbox-container {
  height: 100%;
  position: relative;
  z-index: 1;
}

.lightbox-content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
}

.lightbox-image {
  img {
    box-sizing: border-box;
    max-height: 100vh;
    max-width: 100vw;
    width: auto;
  }
}

.lightbox-media {
  .audio-container,
  .video-container {
    box-sizing: border-box;
    max-width: 100vw;
    padding: $element-margin;
    width: 50rem;
  }

  .audio {
    background-color: $light-gray;
  }
}

.lightbox-btn {
  color: $white;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1;
  padding: $element-padding / 2 0.75 * $element-padding;
  transition: opacity 0.2s ease-in;
  vertical-align: middle;

  .ico {
    vertical-align: middle;
  }

  &:hover {
    opacity: $hover-opacity;
  }
}

a.lightbox-btn { // scss-lint:disable QualifyingElement
  @include anchor-reset;
  @extend .lightbox-btn;
}

button.lightbox-btn { // scss-lint:disable QualifyingElement
  @include button-reset;
  @extend .lightbox-btn;
}
