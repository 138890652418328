$activity-dropdown-width: 32rem;
$activity-dropdown-mobile-width: 18rem;

.activity-dropdown {
  .dropdown-menu {
    overflow: hidden;
    width: $activity-dropdown-width;
  }
  .activity-notification-icon {
    font-size: 24px;
  }

  .activity-controls {
    background-color: $light-gray;
    border-top: 1px solid $border-color;
    margin-bottom: -$element-margin;
    margin-left: -$element-margin;
    margin-right: -$element-margin;
    padding: $element-padding;
    text-align: right;
  }
}

.activity-notification, // scss-lint:disable QualifyingElement
button.activity-notification {
  overflow: visible;
  position: relative;

  .activity-notification-counter {
    // font-size: 24px;
    // width: 17px;
    // height: 16px;
    // font-size: 16px;
    left: calc(100% - 0.7rem);
    border: 1px solid white;
    border-radius: 12px;
    line-height: 12px;
    position: absolute;
    top: -0.4rem;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
