$team-logo-preview-desktop-size: 12rem;
$team-logo-preview-mobile-size: 8rem;

.team-logo-picker {
  text-align: center;

  .team-logo-preview {
    display: block;
    width: $team-logo-preview-mobile-size;
    height: $team-logo-preview-mobile-size;
    margin: 0 auto;
    margin-bottom: $element-margin;
    transition: all 0.2s ease-in;
    border-radius: 50%;
    background-color: $disabled-color;
    background-image: url("/images/default-team-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include desktop-only {
      width: $team-logo-preview-desktop-size;
      height: $team-logo-preview-desktop-size;
    }

    &.disabled {
      cursor: default;
      opacity: $disabled-opacity;
    }
  }
}
