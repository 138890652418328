$loading-dot-size: 0.75rem;
$loading-dot-color: $dark-gray;
$loading-dot-spacing: 0.25rem;

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.loading-dots {
  display: inline-block;

  :nth-child(2) {
    animation-delay: 0.2s;
  }

  :nth-child(3) {
    animation-delay: 0.4s;
  }
}

.loading-dot {
  animation: blink 1.5s infinite;
  background-color: $loading-dot-color;
  border-radius: 50%;
  display: inline-block;
  height: $loading-dot-size;
  margin: 0 $loading-dot-spacing;
  width: $loading-dot-size;
}

.spinner_container {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}


.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #039BE5);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 4px),
    #000 0
  );
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}
