$progress-bar-color: $border-color !default;
$progress-bar-fill-color: $info-color !default;
$progress-bar-danger-color: $danger-color !default;
$progress-bar-size: 0.75rem !default;

.progress-bar {
  background-color: $progress-bar-color;
  display: block;
  height: $progress-bar-size;
  position: relative;

  .progress-bar-fill {
    background-color: $progress-bar-fill-color;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.25s ease-in;
    transition-property: width, background-color;
    width: 0%;
  }

  &.has-danger {
    .progress-bar-fill {
      background-color: $progress-bar-danger-color;
    }
  }
}
