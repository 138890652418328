
$toast-levels: (
  'danger': $orange,
  'info': $blue,
  'success': $green
) !default;

.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 3rem;
  padding: 0 $element-padding;
  overflow: hidden;
  z-index: 9999;

  @include mobile-only {
    left: 0;
  }
}

.toast {
  @include button-reset;
  @include shadow;
  position: relative;
  display: block;
  max-width: 100%;
  margin-bottom: $element-margin;
  padding: $element-padding;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: $white;
  text-align: left;

  .toast-alert {
    font-weight: normal;
  }

  @include mobile-only {
    width: 100%;
  }

  @each $level, $color in $toast-levels {
    &.toast-#{$level} {
      .toast-alert {
        color: $color;
      }
    }
  }
}
