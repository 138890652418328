.readmore {
  position: relative;

  &::before {
    background: linear-gradient(to bottom, $transparent, $white);
    bottom: 0;
    content: "";
    height: 3rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
