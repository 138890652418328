@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  @include desktop-only-large {
    height: 5rem;
  }
}

@media screen and (max-width: 400px) {
  .header {
    flex-direction: column;
  }

  .datepicker {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
    padding: 1rem 0;
  }
}

.day_row {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #e7e7e7;
  padding: 1.25rem;

  &.current_day {
    background-color: #039BE51A;

    .show_date_container {
      color: #000;
    }
  }

  .show_date_container {
    color: #8c8c8c;
    flex: none;
    width: 5.5rem;
    padding-top: 0.375rem;

    .month_day {
      font-size: 1rem;
      font-weight: 700;
    }

    .day_of_the_week {
      font-size: 0.75rem;
    }
  }
}

.no_slots {
  margin-left: 0.375rem;
}

.time_slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;


  .time_slot_container {
    margin: 0.375rem;
    border-radius: 0.75rem;
    width: 11.25rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .time_slot_text {
      text-align: center;
      margin: 0 auto;
      font-size: 0.875rem;
      color: #000;
      font-weight: 500;
    }

  }
}

.multi_coach_day_panel_container {
  .time_slots {
    justify-content: space-evenly;
  }
}

.coach_list {
  display: flex;
  overflow-x: auto;

  .multi_list {
    flex: 1;
    min-width: 15rem;

    border-right: 1px solid #e7e7e7;

  }

  .single_list {
    width: 100%;
  }
}

.half_of_day {
  height: 20rem;

  @include mobile-only {
    overflow: none;
  }

  overflow-y: scroll;
  border-right: 1px solid #e7e7e7;
}

.time_header_container {
  background-color: #f4f4f4;
  font-size: 14px;
  height: 1.75rem;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  color: #8c8c8c;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: grey;
  font-size: 14px;
  height: 50vh;
}

.iconStyle{
  font-size: 36px;
  color: grey;
  margin-bottom: 20px;
}

