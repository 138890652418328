.pager {
  display: block;
}

.pager-btn {
  @include base-button;
  @include theme-outline-button($active-color);

  &.active {
    background: transparent;
    border: 0;
    color: $muted-text-color;
  }
}

.pager-btn-rail {
  align-items: center;
  display: flex;
  justify-content: center;

  .bullets {
    color: $muted-text-color;
    flex: 0 0 auto;
    margin: 0 0.25rem;
  }

  .pager-btn {
    flex: 0 0 auto;
    margin: 0 0.25rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
