.invitation-count {
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: #ffffff;
  background-color: #E54500;
  border-radius: 12px;
  text-align: center;
  margin-left: 6px;
}

.invitation-details {
  display: flex;
  flex-wrap: nowrap;

  .invitation-avatar {
    margin-right: $element-margin;
  }

  .invitation-summary {
    flex: 1 1 auto;
  }

  .invitation-info {
    color: $muted-text-color;
    font-size: $font-size-small;
    // margin-bottom: $element-margin;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
