$pill-select-item-color: $light-gray;
$pill-value-max-width: 10rem;

.pills {
  @include field;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: default;
  height: auto;
  line-height: 1;
  padding: 0 0.75rem;
  height: 100%;
  min-height: 44px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 7px 0;
  text-align: left;

  .pill-search-icon {
    display: inline-block;
    vertical-align: middle;
  }

  .pill-search {
    border: 0;
    display: inline-block;
    background-color: transparent;
    height: 2.125rem;
    line-height: 1;
    margin: 0;
    padding: 0.25rem;
    width: auto;
  }

  .pill-value {
    @include border;
    background-color: $pill-select-item-color;
    border-radius: $border-radius;
    box-sizing: border-box;
    display: inline-block;
    margin: 0.1875rem;
  }

  .pill-remove-value {
    padding: 8px;
    font-size: 9px;
    color: #5e5e5e;
    font-weight: bold;
    height: 100%;
  }

  .pill-value-label {
    display: inline-block;
    font-size: $font-size-small;
    padding: 8px;
    vertical-align: middle;
  }

  .pill-value-label {
    max-width: $pill-value-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pill-remove-value {
    @include button-reset;
    border-right: 1px $border-color solid;
    // line-height: 1;
  }

  .pill-dropdown {
    width: 100%;

    li > a {
      // scss-lint:disable SelectorDepth
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:not(.disabled) {
    cursor: text;

    &.focused {
      border-color: $active-border-color;
    }

    .pill-value {
      cursor: pointer;

      &.highlight {
        background-color: hover-color($pill-select-item-color);
      }
    }

    .pill-remove-value {
      &:hover {
        background-color: hover-color($pill-select-item-color);
      }
    }
  }

  &.disabled {
    @include disable;
    background-color: $disabled-color;
  }
}
