.tooltip {
  position: relative;
  display: flex;
  justify-content: flex-start;
  color: black;
  align-items: center;
}

.tooltipTitle {
  font-size: 16px !important;
  font-weight: 600px !important;
  margin-bottom: 2px;
}

.tooltipSubTitle {
  font-size: 14px;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  border-bottom: 1px solid #ffffff;
  margin-top: 6px;
  cursor: pointer;
}

.tooltip .tooltiptext {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 300;
  visibility: hidden;
  background-color: #039be5;
  white-space: nowrap;
  color: white;
  position: absolute;
  border-radius: 4px;
  width: fit-content;
  z-index: 2;
  padding: 8px 16px;
  bottom: 150%;
  font-size: 14px;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  border-radius: 12px;

  box-sizing: border-box;
}

.tooltip .tooltiptext_clicked {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  visibility: visible;
  background-color: var(--text-color);
  white-space: nowrap;
  color: white;
  position: absolute;
  border-radius: 4px;
  width: fit-content;
  z-index: 2;
  padding: 8px 16px;
  bottom: 150%;
  box-sizing: border-box;
}

.tooltip .multiline {
  white-space: normal;
  width: 254px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: #039be5 transparent transparent transparent;
}

.tooltip .tooltiptext_right {
  margin-right: -14px;
  right: 0;
}

.tooltip .tooltiptext_right::after {
  right: 14px;
}

.tooltip:hover .tooltiptext_right {
  visibility: visible;
  margin-bottom: 10px;
}

.tooltip .tooltiptext_left {
  margin-left: -14px;
}

.tooltip:hover .tooltiptext_left {
  visibility: visible;
  margin-bottom: 10px;
}

.tooltip .tooltiptext_center {
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.tooltip:hover .tooltiptext_center {
  visibility: visible;
  margin-bottom: 10px;
}

.tooltip:hover .tooltiptext_center {
  visibility: visible;
  align-items: center;
  margin-bottom: 10px;
}

.selected_tooltip > path {
  fill: white;
}

.iconWhite {
  color: #ffffff;
}

.iconGray {
  color: #8c8c8c;
}

@media (max-width: 768px) {
  .tooltip .tooltiptext_right,
  .tooltip .tooltiptext_left {
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }

  .tooltip:hover .tooltiptext_right,
  .tooltip:hover .tooltiptext_left {
    visibility: visible;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
  }

  .tooltip .multiline {
    white-space: normal;
    width: 227px;
    font-size: 12px;
  }

  .tooltip .tooltiptext_right::after {
    right: 50%;
  }
}
