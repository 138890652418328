
$capsule-themes: (
  'primary': $blue,
  'secondary': $dark-gray,
  'info': $info-color,
  'success': $success-color,
  'danger': $danger-color
) !default;

$capsule-sizes: (
  'sm': $font-size-small,
  'xs': $font-size-tiny
);

.capsule {
  @include inline-flex;
  @include align-items(center);
  padding: 0 0.35rem;
  border-radius: 9999px;
  color: $white;
  font-size: 1rem;
  line-height: $line-height;
  text-align: center;

  @each $type, $color in $capsule-themes {
    &.capsule-#{$type} {
      background-color: $color;
    }
  }

  @each $type, $size in $capsule-sizes {
    &.capsule-#{$type} {
      font-size: $size;
    }
  }
}

.capsule-outlined-muted {
  background-color: $light-gray;
  border: 1px solid $border-color;
  color: $muted-text-color;
  font-weight: normal;
}
