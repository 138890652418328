@import "../mixins";
@import "../settings";

$button-themes: (
  "primary": $blue,
  "secondary": $dark-gray,
  "info": $info-color,
  "success": $success-color,
  "danger": $danger-color,
) !default;
$button-outline-color: $white !default;
$button-outline-border-color: $border-color !default;

%standard-button {
  transition-duration: $transition-time;
  transition-property: opacity, background-color;
  transition-timing-function: ease-out;
  color: $white;

  .loading-dot {
    background-color: $white;
  }
}

%outline-button {
  transition-duration: $transition-time;
  transition-property: opacity, color, border-color, background-color;
  transition-timing-function: ease-out;
  background-color: $button-outline-color;
}

%link-button {
  padding: 0;
  transition-duration: $transition-time;
  transition-property: opacity, color;
  transition-timing-function: ease-out;
  background-color: transparent;
  font-weight: normal;
}

%base-button {
  display: inline-block;
  position: relative;
  margin-bottom: 1px;
  padding: $control-vertical-padding $control-horizontal-padding;
  border: 1px transparent solid;
  border-radius: 8px;
  outline: 0;
  font-size: $font-size;
  font-weight: bold;
  line-height: $line-height;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;

  &:disabled,
  &.disabled {
    cursor: default;
    opacity: $disabled-opacity;
  }
}

@mixin base-button {
  @extend %base-button;
}

@mixin style-button-elements {
  // sass-lint:disable no-qualifying-elements force-element-nesting force-attribute-nesting
  .btn,
  a.btn,
  button.btn,
  input[type="button"].btn,
  input[type="submit"].btn {
    @content;
  }
  // sass-lint:enable no-qualifying-elements force-element-nesting force-attribute-nesting
}

@mixin theme-link-button($color) {
  @extend %link-button;
  color: $color;

  &:not(:disabled):not(.disabled) {
    // sass-lint:disable-line force-pseudo-nesting
    &:hover,
    &:focus {
      color: hover-color($color);
    }
  }
}

@mixin theme-outline-button($color) {
  @extend %outline-button;
  border-color: $button-outline-border-color;
  color: $color;

  &:not(:disabled):not(.disabled) {
    // sass-lint:disable-line force-pseudo-nesting
    &:hover,
    &:focus {
      border-color: hover-color($button-outline-border-color);
      background-color: $light-gray;
      color: hover-color($color);
    }
  }
}

@mixin theme-button($color) {
  @extend %standard-button;
  background-color: $color;

  &:not(:disabled):not(.disabled) {
    // sass-lint:disable-line force-pseudo-nesting
    &:hover,
    &:focus {
      background-color: hover-color($color);
    }
  }
}

a.btn {
  // sass-lint:disable-line no-qualifying-elements force-element-nesting
  @include anchor-reset;
}

button.btn {
  // sass-lint:disable-line no-qualifying-elements force-element-nesting
  @include button-reset;
}

@include style-button-elements {
  @include base-button;

  &.btn-link {
    @include theme-link-button($link-color);
    color: #039be5;

    &:first-child {
      margin: calcRems(24px) 0;
    }
  }

  @each $type, $color in $button-themes {
    &.btn-#{$type} {
      @include theme-button($color);
    }

    &.btn-outline-#{$type} {
      @include theme-outline-button($color);
    }
  }

  &.ico {
    padding: $control-vertical-padding;
    font-size: 1.5rem;
    line-height: 1;
  }

  &.pending {
    .loading-dots {
      opacity: 1;
    }

    .btn-content {
      opacity: 0;
    }
  }

  .loading-dots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 0.2s ease-in;
    opacity: 0;
  }

  .btn-content {
    transition: opacity 0.2s ease-in;
    opacity: 1;
  }

  .loading-dot {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.125rem;
  }
}

.upload-btn {
  display: inline-block;
  position: relative;
  overflow: hidden;

  [type="file"] {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}

.btn-group {
  font-size: 0;

  > .btn {
    border-radius: 8px;

    &:first-of-type:not(:last-of-type) {
      // sass-lint:disable-line force-pseudo-nesting
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type:not(:first-of-type) {
      // sass-lint:disable-line force-pseudo-nesting
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-of-type):not(:last-of-type) {
      // sass-lint:disable-line force-pseudo-nesting
      border-radius: 0;
    }
  }
}
