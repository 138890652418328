@keyframes a-pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.8;
    transform: scale(0.975);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.a-pulse {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 2;
  animation-name: a-pulse;
  animation-timing-function: ease-in-out;
}
