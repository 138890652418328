$t-space-readonly-chip-size: 2rem !default;
$t-space-readonly-chip-color: $danger-color !default;

.t-space {
  .image-holder {
    display: inline-block;
    position: relative;
  }

  .readonly-chip {
    bottom: 0;
    color: $t-space-readonly-chip-color;
    font-size: 2rem;
    position: absolute;
    right: 0;
    text-decoration: none;
  }
}
