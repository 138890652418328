%list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.segment-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  color: #000000;

  &:nth-child(2) {
    color: #8c8c8c;
    font-size: 16px;
    line-height: 19.5px;
  }
}

.no-space-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.null-posts {
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin-bottom: 1rem;
  }

  & h3 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 26.66px;
    color: #000000;
    margin-bottom: 0.5rem!important;
  }

  & .null-result-summary {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin: 0!important;
    line-height: 22.4px;
    font-weight: 400;
  }
}

.space_template_posts {
  margin-top: 20px;
}

@mixin list-reset {
  @extend %list-reset;
}
