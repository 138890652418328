$avatar-sizes: (
  "sm": 2.75rem,
  "md": 64px,
  "lg": 8rem,
  "xl": 12rem,
) !default;

$default-avatar-size: map-get(
  $map: $avatar-sizes,
  $key: "sm",
) !default;
$avatar-preview-size: map-get(
  $map: $avatar-sizes,
  $key: "lg",
) !default;

.avatar {
  width: 44px;
  min-width: 44px;
  max-width: $default-avatar-size;
  height: 44px;
  min-height: 44px;
  max-height: $default-avatar-size;
  border-radius: 50%;
  background-color: #f4f4f4;

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;

  &.font-lg {
    font-size: 20px;
  }
}

.space-memberships-member-avatar {
  width: 56px;
  height: 56px;
}

@each $key, $size in $avatar-sizes {
  .avatar-#{$key} {
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }

  .avatar-#{$key}-m {
    @include mobile-only {
      max-height: $size;
      max-width: $size;
    }
  }

  .avatar-#{$key}-d {
    @include desktop-only {
      max-height: $size;
      max-width: $size;
    }
  }
}

.avatar-preview {
  background-position: center;
  background-size: cover;
  height: $avatar-preview-size;
  width: $avatar-preview-size;
}
