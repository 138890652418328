
// scss-lint:disable IdSelector, QualifyingElement
//  Must match selector of base style.
#notifications-list li.unread,
#invitations-list li.unread {
  background-color: $white;
}
// scss-lint:enable IdSelector, QualifyingElement

.notifications {
  a {
    background-color: $white;
    border: 1px $border-color solid;
    padding: 15px;

    &.unread {
      background-color: $white;
      border: 1px $active-border-color solid;

      &:hover {
        border-color: hover-color($active-border-color);
      }
    }

    &:hover {
      background-color: $hover-option-color;
    }
  }
}
