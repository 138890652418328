.posting-locations-dropdown {
  position: relative;
  .site-navbar-query {
    position: sticky;
    top: 0;
    padding: 1rem;
    z-index: 3;
    background-color: #fff;
    .input-append {
      margin: 0;
    }

    input {
      font-size: 1rem;
    }
  }

  .locations-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .locations-list-label {
    padding: 0 1rem 1rem 1rem;
  }

  .channel {
    display: block;
    content: "";
    height: 22px;
    left: 33px;
    top: 0;
    position: absolute;
    border-left: 1px solid #ddd;
    margin: -11px 0;
    z-index: 0;
  }
}

.filter-label {
  color: #8c8c8c;
  margin-bottom: 0.5rem;
}

label {
  // color: $muted-text-color;
  color: #5e5e5e;
  font-size: $font-size-small;
  font-weight: 700;
}

.tooltip-label {
  position: relative;
}

.control-group {
  margin-bottom: 20px;
  position: relative;

  .disabled {
    & > span {
      color: #ddd !important;
    }
  }

  .checkbox:has(.fa_custom_checkbox) {
    input[type="checkbox"]:disabled + label {
      opacity: 0;
    }
  }
  .filter-type-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .filter-checkbox-control-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    & label {
      color: #8c8c8c !important;
      font-size: 1rem;
      line-height: 1.3125rem;
      font-weight: 400;
    }
  }

  .custom_checkbox {
    label {
      opacity: 0;
    }

    &:has(.plus-icon) {
      .plus-icon {
        // display: none;
        padding: 5px !important;
        background-size: 0.5rem !important;
      }
    }

    &:has(.filters-custom-checkbox) {
      .fa_custom_checkbox {
        top: -7.5px !important;
      }
    }

    .fa_custom_checkbox {
      top: -3.5px;
      left: 0;
      position: absolute;
      box-sizing: border-box;
      display: block;
      transition: all 0.2s ease-out;
      font-size: medium;

      & .fa-circle {
        color: #e6e6e6;
      }

      & .fa-check-circle {
        color: #039be5;
      }
    }
  }

  .filters-custom-checkbox {
    font-size: 1.375rem !important;
  }

  > label:not(.upload-btn) {
    display: block;
  }

  &.dropdown-item {
    padding: 0.625rem 1rem;
    display: flex;
    flex-direction: row;
    max-width: 400px;

    .dropdown-item-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.75rem;

      .note {
        font-weight: 400;
        margin-bottom: 0;
      }

      .avatar {
        min-width: auto;
        min-height: auto;
        width: 2.5rem;
        height: 2.5rem;
        background: #f4f4f4;
        border-radius: 100%;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        & i,
        img {
          font-size: 1.25rem;
          z-index: 1;
        }

        & .ico-tspace {
          font-size: 1.5rem;
          font-weight: 400;
        }
      }
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(3, 155, 229, 0.06),
          rgba(3, 155, 229, 0.06)
        ),
        #fff;
      cursor: pointer;
    }

    & > img {
      align-items: flex-start;
      border-radius: 22px;
      width: 44px;
      height: 44px;
      z-index: 1;
    }
  }

  &.checkbox-control-group-set .checkbox-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.checkbox-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
  }

  &.checkbox-control-group,
  &.radio-control-group {
    // white-space: nowrap;
    display: flex;
    align-items: center;

    > label {
      display: inline-block;
      font-size: 1rem;
      font-weight: normal;
      vertical-align: middle;

      &.disabled {
        color: #ddd;
        cursor: default;

        & > span {
          color: #ddd;
          cursor: default;
        }
      }
    }
  }

  &.checkbox-control-group {
    > .checkbox {
      margin-right: $element-margin / 2;
      vertical-align: middle;
    }
  }

  &.radio-control-group {
    > input[type="radio"] {
      margin-right: $element-margin / 2;
      vertical-align: middle;
    }
  }
}

.checkbox-control-group-set,
.radio-control-group-set {
  margin-bottom: $element-margin;

  > .control-group {
    margin: 0;
  }
}

.input-append {
  @include flexbox;
  @include flex-wrap(nowrap);

  .btn {
    border-radius: 0;

    &:not(:first-child) {
      border-left: 0;
    }
  }
}

.helper-text {
  color: $muted-text-color;
  font-size: $font-size-small;

  &.error {
    color: $danger-color;
  }
}

.control-group-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  & > div:first-child {
    flex: 1;
  }
}

.search-form {
  padding: 20px;

  & > h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #000000;

    margin-bottom: 20px;
  }
}

.search-results {
  background: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
}

.search-input {
  position: relative;

  & > i {
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 14px;
  }
}
