$notification-details-attachment-size: 2.5rem;

.notification-details {
  display: flex;
  flex-wrap: nowrap;

  .notification-avatar {
    margin-right: $element-margin;
  }

  .notification-attachment {
    flex-shrink: 0;
    margin-left: $element-margin;
  }

  .notification-thumbnail {
    background-position: center;
    background-size: cover;
    height: $notification-details-attachment-size;
    width: $notification-details-attachment-size;
    border-radius: 8px;
  }

  .notification-attachment-icon {
    color: $white;
    font-size: (3 / 4) * $notification-details-attachment-size;
    // padding: (1 / 8) * $notification-details-attachment-size;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.ico-document,
    &.ico-video,
    &.ico-microphone {
      border-radius: 8px;
    }

    &.ico-document {
      background-color: $document-icon-color;
    }

    &.ico-video {
      background-color: $video-icon-color;
    }

    &.ico-microphone {
      background-color: $audio-icon-color;
    }
  }

  .notification-info {
    color: $muted-text-color;
    font-size: $font-size-small;
  }

  .notification-summary {
    flex: 1 1 auto;
  }
}
