
.member-invite-roster-container {
  width: 100%;
  overflow-x: auto;

  .member-invite-roster {
    width: 100%;
    min-width: 600px;

    .member-invite-roster-header {
      @include border;
      background-color: $light-gray;
      color: $muted-text-color;
      text-align: left;
    }

    .member-invite-roster-label {
      padding: 0.75rem 0.5rem;

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 1rem;
      }
    }

    .member-invite-roster-list {
      @include border;
    }

    .member-invite-roster-control-group {
      vertical-align: top;

      &:first-child {
        .member-invite-roster-control {
          padding-top: 1rem;
        }
      }

      &:last-child {
        .member-invite-roster-control {
          padding-bottom: 1rem;
        }
      }
    }

    .member-invite-roster-control {
      padding: 0.5rem;

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 1rem;
      }

      &.member-invite-roster-control-sm {
        width: 6rem;
      }
    }
  }
}
