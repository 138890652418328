%anchor-reset-content {
  box-sizing: border-box;
  text-decoration: none;
}

%anchor-reset {
  @extend %anchor-reset-content;
  color: $link-color;

  &:hover,
  &:active,
  &:link {
    @extend %anchor-reset-content;
  }
}

@mixin anchor-reset {
  @extend %anchor-reset;
}
