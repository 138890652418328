.caret {
  border: 0;
  font-family: 'coachnow-icons' !important; // scss-lint:disable ImportantRule
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: 1em;
  line-height: 1;
  text-transform: none;
  width: 1em;

  &::before {
    content: "\e931";
  }
}

.dropdown {
  .caret {
    margin-left: 4px;
    margin-top: 3px;
  }
}
