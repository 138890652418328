$media-sprites: (
  "doc": (1, 3),
  "xls": (1, 4),
  "ppt": (1, 5),
  "pdf": (1, 6),
  "img": (1, 0),
  "vid": (1, 1),
  "mic": (1, 2),
  "img-mute": (0, 0),
  "vid-mute": (0, 1),
  "mic-mute": (0, 2),
  "doc-mute": (0, 3),
  "doc-exclamation-mute": (0, 4),
);

$media-sprite-sizes: (
  "xs": 28,
  "sm": 56,
  "md": 108
);

$media-sprite-highlight-opacity: 0.6 !default;

@mixin media-sprite($row, $column, $size) {
  $scale: $size / 256;
  width: 256px * $scale;
  height: 256px * $scale;
  background-position: (-256px * $column * $scale) (-256px * $row * $scale);
  background-size: (1792px * $scale) (512px * $scale);
}

.media-sprite {
  display: inline-block;
  background-image: url("/images/media-sprite.png"); // scss-lint:disable NameFormat
  background-repeat: no-repeat;
}

@each $size, $dim in $media-sprite-sizes {
  @each $name, $pos in $media-sprites {
    .media-sprite-#{$size} {
      &.media-sprite-#{$name} {
        @include media-sprite(nth($pos, 1), nth($pos, 2), $dim);
      }
    }
  }
}

a {
  .media-sprite {
    @include anchor-reset;
    transition: opacity $transition-time ease-out;
    opacity: 1;

    &:hover {
      opacity: $media-sprite-highlight-opacity;
    }
  }
}
