@import "~app/styles/theme/golf-digest/utilities/_responsive.scss";

.page_background {
  background-color: #f4f4f4;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  // .navbar_container {
  //   position: sticky;
  //   top: 0;
  //   background-color: #f4f4f4;
  //   width: 100%;
  //   height: 80px;
  //   padding: 20px;
  //   z-index: 1;
  // }

  .site_logo {
    max-width: 195px;
    width: 100%;
    display: flex;
    margin: 40px auto 56px auto;
  }

  .content_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 28px 20px;
    background-color: #ffffff;
    border-radius: 16px;
    max-width: 400px;
    margin: 0 20px 40px 20px;

    .promocode_applied {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      border-radius: 12px;
      border: 1px solid rgba(67, 160, 71, 0.2);
      background-color: rgba(67, 160, 71, 0.1);
      padding: 1rem;

      .promo_applied_wrapper {
        display: flex;
        flex-direction: column;
        color: #43a047;

        .price_tag {
          width: 24px;
          height: 24px;
        }

        .pga_promo_applied {
          line-height: 19.36px;
          font-size: 16px;
          font-weight: 700;
        }

        .coupon_description {
          line-height: 16.94px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 8px;

      .header_title {
        font-size: 24px;
        font-weight: 700;
        color: #000000;
        line-height: 29.05px;
        text-align: left;
      }

      .header_description {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 19.36px;
      }
    }
  }
}
