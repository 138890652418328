$tab-text-inactive-color: $inactive-color;
$tab-text-active-color: #E54500;
$tab-indicator-width: 3px;
$tab-indicator-active-color: #E54500;
$tab-indicator-inactive-color: transparent;
$tab-padding: $element-padding;
$tab-icon-size: 20px;
$vertical-tab-vertical-padding: $element-padding;
$vertical-tab-horizontal-padding: 1rem;
$vertical-tab-icon-size: 1.25rem;

.tab-icon {
  color: $muted-text-color;
  font-size: $tab-icon-size;

  @include mobile-only {
    font-size: $tab-icon-size;
  }
}

.tabs {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  padding-top: 0;

  li {
    border-bottom: $tab-indicator-width solid $tab-indicator-inactive-color;
    color: $tab-text-inactive-color;
    // flex: 1 0 auto;
    text-align: center;

    a {
      display: flex!important;
      justify-content: center;
      align-items: center;
    }


    &:hover {
      color: $tab-text-active-color;

      .tab-icon {
        color: $tab-text-active-color;
      }
    }

    &.active {
      border-color: $tab-indicator-active-color;
      color: $tab-text-active-color;
      font-weight: 600;

      .tab-icon {
        color: $tab-text-active-color;
      }
    }

    .tab {
      color: inherit;
      display: block;
      padding: $tab-padding;
      transition: color 0.2s ease-out;
      width: 100%;
    }

    > a {
      @include anchor-reset;
      @extend .tab;
    }

    > button {
      @include button-reset;
      @extend .tab;
    }
  }

  &.tabs-compact {
    li {
      margin-right: $tab-padding;
      > a,
      > button {
        padding: 0.75 * $tab-padding 0;
      }
    }
  }
}

.vertical-tabs {
  @include list-reset;
  display: flex;
  flex-direction: column;

  .tab-icon {
    font-size: $vertical-tab-icon-size;
    margin-right: $vertical-tab-horizontal-padding;
  }

  li {
    // @include border;
    border-radius: 8px;
    color: $text-color;
    display: block;

    &:hover {
      color: #039BE5;

      .tab-icon {
        color: #039BE5;
      }
    }

    &.active {
      background: #039BE510;
      color: #039BE5;
      font-weight: 600;

      .tab-icon {
        color: #039BE5;
        font-weight: 600;
      }
    }

    .tab {
      color: inherit;
      display: block;
      padding: $vertical-tab-vertical-padding $vertical-tab-horizontal-padding;
      transition: color 0.2s ease-out;
      width: 100%;
    }

    > a {
      @include anchor-reset;
      @extend .tab;
    }

    > button {
      @include anchor-reset;
      @extend .tab;
    }
  }
}
