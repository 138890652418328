$well-border-color: $border-color !default;
$well-background-color: $light-gray !default;
$well-nothing-text-color: $muted-text-color !default;
$well-nothing-background-color: $light-gray !default;
$well-nothing-base-spacing: 3rem !default;
$mediumScreen: 869px;

@mixin well-variant($fill) {
  background-color: $fill;
}

.well {
  // background-color: $well-background-color;
  // border: 1px solid $well-border-color;
  padding: $element-padding;
  text-align: center;

  &.dialog {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0;
    box-sizing: border-box;
  }
}

.well-loading {
  line-height: 1;
}

.list-container {
  padding: 0;

  td {
    padding: 0;
    width: 100%;
  }
}

.segment_spaces_list {
  width: 100%;
}

.segment_spaces_list > tbody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border-radius: 12px;
  gap: 20px;

  td {
    display: block;
  }

  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
  }
}

.dropdown-menu .well-nothing {
  margin-top: 1rem;
}

.well-nothing {
  @include well-variant($well-nothing-background-color);
  color: $well-nothing-text-color;
  padding: $well-nothing-base-spacing;
  text-align: center;
  position: relative;
  border-radius: 12px;
  // margin-top: 134px;

  i {
    font-style: normal;
  }

  &.well-field {
    @include field;
    background-color: $well-background-color;
    text-align: left;
  }

  .empty-list-container {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-top: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .null-result .null-result-hero {
    }
  }
}

@media screen and (max-width: $mediumScreen) {
  .well-nothing {
    padding: 0;
    // margin-top: 60px;
  }

  .empty-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px !important;
  }
}

@media screen and (max-height: $mediumScreen) {
  .well-nothing {
    margin-top: 0px;
  }
}
