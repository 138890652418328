.activity-nav-tabs {
  .activity-nav-tab {
    align-items: center;
    display: flex;
  }

  .activity-tab-label {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    margin-right: $element-margin / 2;
  }
}
