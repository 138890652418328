$post-attachment-letterbox-color: #e8e8e8 !default;
$post-attachment-height: 400px !default;
$post-attachment-footer-color: $dark-gray !default;
$post-attachment-footer-text-color: $white !default;
$post-attachment-single-media-overlay-height: 79px;

.post-attachment {
  .media-type-video,
  .media-type-image {
    background-color: $post-attachment-letterbox-color;
  }

  display: block;

  .post-attachment-body {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .post-attachment-body-reply {
    display: block;
    max-width: 525px;
    width: 100%;

    & > .post-attachment-single {
      img {
        border-radius: 12px;
      }
    }
  }

  .post-attachment-footer {
    background-color: $post-attachment-footer-color;
    color: $post-attachment-footer-text-color;
  }
}

a.post-attachment {
  // scss-lint:disable QualifyingElement
  text-decoration: none;
}

.post-attachment-single {
  position: relative;
  display: initial;
  width: 100%;
  max-height: $post-attachment-height;
  text-align: center;
  cursor: pointer;

  img {
    max-height: $post-attachment-height;
    min-height: 32px;
    width: auto;
  }

  .media-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $post-attachment-single-media-overlay-height;
    height: $post-attachment-single-media-overlay-height;
    margin-top: -($post-attachment-single-media-overlay-height / 2);
    margin-left: -($post-attachment-single-media-overlay-height / 2);
    background: url("/images/play-overlay.png") no-repeat center;
    background-size: $post-attachment-single-media-overlay-height
      $post-attachment-single-media-overlay-height;
  }
}

.post-attachment-footer {
  .post-attachment-filename {
    color: $post-attachment-footer-text-color;
    font-weight: bold;
  }
}

.post-audio-attachment,
.post-video-attachment {
  border: 0;

  .audio {
    @include border;
    border-bottom: 0;
  }

  .post-attachment-body {
    display: block;
  }

  .post-processing {
    @include border;
  }
}

.post-video-attachment {
  border: 0;
}

.post-image-attachment {
  .post-attachment-body {
    img {
      max-height: $post-attachment-height;
      min-height: 40px;
      width: auto;
    }
  }
}

.post-document-attachment {
  .post-attachment-body {
    height: 9rem;
  }
}

.post-processing {
  color: $muted-text-color;
  padding: 2 * $element-padding;
  text-align: center;
}

.audio-container {
  .audio {
    cursor: pointer;
  }

  .audio-controls {
    align-items: center;
    background-color: $darker-gray;
    display: flex;
    font-size: $font-size-small;
    padding: $element-padding / 2;

    .audio-control {
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: 1rem;
      line-height: 1;

      .ico {
        // scss-lint:disable SelectorDepth
        font-size: 1.5rem;
        font-weight: bold;
      }

      &.duration {
        margin-left: $element-padding / 2;
      }
    }
  }
}

.video-js {
  position: relative;
  box-sizing: border-Box;
  width: 100%;
  .vjs-control-bar {
    background-color: $darker-gray;
    font-size: 0.8rem;
    opacity: 1;
  }
}

.audio-attachment-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #e54500;
  border-radius: 16px;
  margin: 0 20px;

  & > .audio-control {
    border: none;
    margin-right: 16px;
    margin-left: 4px;

    & > img {
      width: 20px;
      height: 24px;
    }
  }

  & > span {
    margin-left: 24px;
  }

  & > .audio {
    width: 80%;

    &.isNotReady {
      background-image: url("/images/default_wave.svg");
    }
  }
}

.feed-post .reply-content-conatiner {
  .audio-attachment-container {
    padding: 16px !important;
    margin: 0 !important;
    width: 100% !important;
    min-width: max-content;
  }
}

.media-audio {
  .audio-attachment-container {
    padding: 16px !important;
    margin: 0 !important;
    width: 100% !important;
    min-width: max-content;

    .audio-control {
      cursor: pointer;
    }
  }
}
