@function hover-color($color) {
  @return darken($color, 10%);
}

@function activate-color($color) {
  @return darken($color, 15%);
}

@function readable-color($color) {
  @if (lightness($color) > 50) {
    @return #{$dark-text-color};
  } @else {
    @return #{$light-text-color};
  }
}

.opaque {
  opacity: 1 !important; // scss-lint:disable ImportantRule
}
