$post-text-size: 10rem !default;
$post-attachment-background: $background-color !default;
$post-attachment-size: 96px !default;
$post-attachment-remove-background: $danger-color !default;
$post-attachment-remove-color: $white !default;

.form-post-container {
  margin: 0;

  .form-post-text {
    @include animate-field(height);
    width: 100%;
    height: $post-text-size;
    resize: none;
    border-radius: 8px;
  }

  .form-post-controls {
    display: flex;
    flex-direction: row;
    height: auto;
    opacity: 1;
    transition: opacity 0.2s ease-out;
    margin-top: 7px;
  }

  .form-post-controls-right,
  .form-post-controls-left {
    display: flex;
    flex-direction: column-reverse;
    font-size: 0;
  }

  &.collapsed {
    overflow: hidden;

    .form-post-text {
      height: $control-height;
      overflow: hidden;
    }

    .form-post-controls {
      height: 0;
      opacity: 0;
      overflow: hidden;
      margin-top: 7px;
    }

    .control-group {
      margin-bottom: 1px;
    }
  }

  @include desktop-only {
    .form-post-controls {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 7px;
    }

    .form-post-controls-right,
    .form-post-controls-left {
      flex-direction: row;
      justify-content: flex-end;

      button + button {
        margin-left: 0.25rem;
      }
    }
  }
}

.post-attachments-preview-list {
  display: flex;
  margin-bottom: $element-margin;
  // margin-left: -$element-padding / 2;
  // margin-right: -$element-padding / 2;

  .post-attachments-preview-item {
    // padding: 0 $element-padding / 2;
  }
}

.post-attachment-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.post-attachment-preview {
  @include border;
  position: relative;
  width: $post-attachment-size;
  height: $post-attachment-size;
  border-radius: 3px;
  background-color: $post-attachment-background;
  background-position: center;
  background-size: cover;

  .post-attachment-preview-remove {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    border-radius: 0 2px 0 2px; // sass-lint:disable-line shorthand-values
    background-color: $post-attachment-remove-background;
    color: $post-attachment-remove-color;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 868px) {
  .ml-1 {
    margin-left: 0 !important;
  }

  .btn {
    width: 100%;
    // margin-bottom: 0.5rem !important;
  }
}
