@mixin label-variant($fill) {
  background-color: $fill;
}

.label {
  text-shadow: none;
  font-size: 11px;
  padding: 2px 6px;
  text-transform: capitalize;
  color: #000;

  &.label-info {
    @include label-variant($info-color);
  }

  &.label-success {
    @include label-variant($success-color);
  }

  &.label-warning {
    @include label-variant($danger-color);
  }
}

